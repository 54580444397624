import { useState, useEffect } from 'react';
import { getSerialOptionalTitle } from '../apis/_axios/fetchData';

const initialColumnNames = [
  '설치일자',
  'FAB-설치',
  'BAY-설치',
  'DIVISION-공정',
  '장비사',
  'MODEL',
  'CODE(설비명)',
  '챔버',
  'GV사',
  'GV I-BOX설치사',
  'DRY PUMP제조사',
  'DRY PUMP 모델',
  'CT길이(m)',
  'PV',
  'SV',
  'DIP',
  '적요',
  '제품상태',
];
const AUXILIARY_COLUMNS = [
  'installation_date',
  'fab_installation',
  'bay_installation',
  'division_process',
  'equipment_company',
  'model',
  'equipment_name',
  'chamber',
  'gv_company',
  'gv_installation_company',
  'dry_pump_manufacturer',
  'dry_pump_model',
  'ct_length',
  'pv',
  'sv',
  'dip',
  'remark',
  'product_status',
  'additional_item_1',
  'additional_item_2',
  'additional_item_3',
  'additional_item_4',
  'additional_item_5',
  'additional_item_6',
  'additional_item_7',
  'additional_item_8',
  'additional_item_9',
  'additional_item_10',
];
const createColumnArray = (ids, names) => {
  return ids.map((id, index) => ({
    id,
    Header: names[index] || id, // names 배열의 길이가 ids 배열의 길이보다 작을 경우 id를 사용
    accessor: id,
  }));
};
const useColumnsMapper = () => {
  const [columnNames, setColumnNames] = useState(initialColumnNames);
  const [columnsArray, setColumnsArray] = useState([]);
  useEffect(() => {
    getSerialOptionalTitle()
      .then((res) => {
        const additionalItems = Object.values(res.data.message);
        setColumnNames((prevColumnNames) => {
          const newColumnNames = [...prevColumnNames, ...additionalItems];
          return [...new Set(newColumnNames)]; // 중복 항목 제거
        });
      })
      .catch((error) => {
        console.error(
          'Failed to fetch data from getSerialOptionalTitle API',
          error
        );
      });
  }, []);
  useEffect(() => {
    if (columnNames.length > 0) {
      const mappedColumns = createColumnArray(AUXILIARY_COLUMNS, columnNames);
      setColumnsArray(mappedColumns);
    }
  }, [columnNames]);
  return columnsArray;
};

export default useColumnsMapper;
