import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const MobileOnlyAccess = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        padding: 2,
      }}
    >
      <Typography variant='h4' gutterBottom>
        모바일 전용 페이지
      </Typography>
      <Typography variant='body1' paragraph>
        이 페이지는 모바일 기기에서만 접근 가능합니다. 데스크톱에서는 이용할 수
        없습니다.
      </Typography>
    </Box>
  );
};

export default MobileOnlyAccess;
