import React, { useEffect } from 'react';
import { Box, Stack, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Logo from '../../img/logibear.png';
import { useNavigate } from 'react-router-dom';

const SignOutButton = styled(Button)(({ theme }) => ({
  borderRadius: 25,
  padding: '12px 0',
  fontSize: '1rem',
  fontWeight: 'bold',
  textTransform: 'none',
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

const SignOut = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('token');
    const timer = setTimeout(() => {
      navigate('/signin');
      window.location.reload();
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        padding: '0 20px',
      }}
    >
      <Box
        component='img'
        src={Logo}
        sx={{ width: '200px', marginBottom: '2rem' }}
      />
      <Stack
        spacing={2}
        alignItems='center'
        sx={{
          borderRadius: '8px',
          backgroundColor: '#f5f5f5',
          padding: '2rem',
          maxWidth: '350px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Typography variant='h6' gutterBottom>
          정상적으로 로그아웃되었습니다.
        </Typography>
        <Typography variant='body1' gutterBottom>
          잠시 후 로그인 페이지로 이동합니다.
        </Typography>
        <SignOutButton
          variant='contained'
          fullWidth
          onClick={() => {
            navigate('/signin');
            window.location.reload();
          }}
        >
          지금 로그인하기
        </SignOutButton>
      </Stack>
    </Box>
  );
};

export default SignOut;
