import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  Button,
  Stack,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import swal from 'sweetalert';
import {
  checkSerialExist,
  checkSerialUnique,
  getSpecificProductInfo,
  regSerialItemInfo,
} from '../../../apis/_axios/fetchData';
import useColumnsMapper from '../../../hooks/useColumnsMapper';

const AddSerialDialog = ({ initialData, productFound, lineList, onClose }) => {
  const [serial, setSerial] = useState('');
  const [productChecked, setProductChecked] = useState(null);
  const [values, setValues] = useState({});
  const [error, setError] = useState({});
  const [useProductLine, setUseProductLine] = useState(false);
  const [searchForProductLine, setSearchForProductLine] = useState(null);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isSerialVerified, setIsSerialVerified] = useState(false);

  const filteredLineList = lineList.filter((line) => line.status);
  const columnsArray = useColumnsMapper();

  const baseColumns = useMemo(
    () => [
      { id: 'writer', Header: '작성자', accessor: 'writer' },
      {
        id: 'product_serial_number',
        Header: '제품S/N',
        accessor: 'product_serial_number',
      },
      { id: 'po', Header: 'PO', accessor: 'po' },
      { id: 'handler', Header: '담당자', accessor: 'handler' },
      { id: 'fab', Header: 'FAB', accessor: 'fab' },
      { id: 'division', Header: 'DIVISION', accessor: 'division' },
      {
        id: 'production_date',
        Header: '생산일',
        accessor: 'production_date',
        type: 'date',
      },
      { id: 'delivery_site', Header: '납품처', accessor: 'delivery_site' },
      {
        id: 'delivery_date',
        Header: '납품일',
        accessor: 'delivery_date',
        type: 'date',
      },
      {
        id: 'delivery_status',
        Header: '납품상태',
        accessor: 'delivery_status',
      },
      { id: 'product_status', Header: '제품상태', accessor: 'product_status' },
    ],
    []
  );

  useEffect(() => {
    if (initialData) {
      setValues((prevValues) => ({
        ...prevValues,
        ...initialData,
      }));
      const newDates = {};
      ['production_date', 'delivery_date', 'installation_date'].forEach(
        (field) => {
          if (initialData[field]) {
            newDates[field] = dayjs(initialData[field]).format(
              'YYYY-MM-DDTHH:mm:ss.SSS'
            );
          }
        }
      );
      setValues((prevValues) => ({ ...prevValues, ...newDates }));

      if (initialData.product_line) {
        const foundLine = lineList.find(
          (line) => line.name === initialData.product_line
        );
        setSearchForProductLine(foundLine || null);
        setUseProductLine(!!foundLine);
      }
    }
  }, [initialData, lineList]);

  const productStatusOptions = ['신품', '철거', '재설치', '기타'];

  const handleSerialChange = (event) => {
    if (!isSerialVerified) {
      setSerial(event.target.value);
    }
  };

  const handleSerialSubmit = async () => {
    try {
      if (useProductLine && !searchForProductLine) {
        swal(
          '제품라인을 선택해주세요',
          '제품라인 사용을 체크하셨습니다',
          'warning'
        );
        return;
      }
      const uniqueCheckResult = await checkSerialUnique(serial);
      if (!uniqueCheckResult.success && uniqueCheckResult.status === 400) {
        const willProceed = await swal({
          title: '이미 등록된 시리얼 정보가 있습니다',
          text: '추가로 등록하시겠습니까?',
          icon: 'warning',
          buttons: ['취소', '확인'],
          dangerMode: true,
        });

        if (!willProceed) {
          return;
        }
      }

      const result = await checkSerialExist(serial);

      if (result.status === 200) {
        if (useProductLine && searchForProductLine) {
          const frontPart = serial.substring(0, serial.length - 11);
          const matchedCode = searchForProductLine.product_codes.find(
            (code) => code === frontPart
          );
          if (matchedCode) {
            const res = await getSpecificProductInfo(
              `product_code=${matchedCode}`
            );
            const product = res.data.message[0];
            const filteredColumns = columnsArray.filter((column) =>
              searchForProductLine.used_auxiliary_items.includes(
                column.accessor
              )
            );
            const transformed = {
              0: {
                id: product.id,
                is_set: product.is_set,
                is_serial: product.is_serial,
                company__code: product.company__code,
                company__name: product.company__name,
                product_group__code: product.product_group__code,
                product_group__name: product.product_group__name,
                product_num: product.product_num,
                product_code: product.product_code,
                safe_quantity: product.safe_quantity || 0,
                keyword: product.keyword,
                name: product.name,
                warehouse_code: product.warehouse_code,
                location: product.location,
                barcode: product.barcode,
                status: product.status,
              },
              product_id: product.id.toString(),
              product_code: product.product_code,
              used_auxiliary_items: filteredColumns.map((column) => ({
                id: column.accessor,
                Header: column.Header,
                accessor: column.accessor,
              })),
            };

            setProductChecked(transformed);
            setValues((prev) => ({
              ...prev,
              serial_number: serial,
              product_line: searchForProductLine.name,
              serial_managed_item: transformed.serial_managed_item_id,
              ...Object.fromEntries(
                transformed.used_auxiliary_items.map((item) => [
                  item.accessor,
                  '',
                ])
              ),
            }));
            setIsSerialVerified(true);
          } else {
            swal(
              `${searchForProductLine.name} 제품라인에 해당하는 시리얼 번호가 아닙니다.`,
              '관리자에게 문의하세요.',
              'error'
            );
          }
        } else {
          const frontPart = serial.substring(0, serial.length - 11);

          if (!Array.isArray(productFound) || productFound.length === 0) {
            swal('오류', '제품 정보를 찾을 수 없습니다.', 'error');
            return;
          }

          const matchedProduct = productFound.find(
            (product) => product.product_code === frontPart
          );

          if (matchedProduct) {
            const transformedAuxiliaryItems =
              matchedProduct.used_auxiliary_items.map((item) => {
                const columnInfo = columnsArray.find(
                  (col) => col.accessor === item
                );
                return columnInfo
                  ? {
                      id: item,
                      Header: columnInfo.Header,
                      accessor: item,
                    }
                  : {
                      id: item,
                      Header: item, // 일치하는 컬럼 정보가 없을 경우 item 자체를 Header로 사용
                      accessor: item,
                    };
              });

            setProductChecked({
              ...matchedProduct,
              used_auxiliary_items: transformedAuxiliaryItems,
            });
            setValues((prev) => ({
              ...prev,
              serial_number: serial,
              serial_managed_item: matchedProduct.serial_managed_item_id,
              // product_id: matchedProduct.product_id,
              ...Object.fromEntries(
                transformedAuxiliaryItems.map((item) => [item.accessor, ''])
              ),
            }));
            setIsSerialVerified(true);
          } else {
            swal(
              '시리얼 관리대상이 아닙니다.',
              '관리자에게 문의하세요',
              'error'
            );
          }
        }
      } else {
        swal('존재하지 않는 시리얼입니다', '시리얼을 다시 확인하세요', 'error');
      }
    } catch (error) {
      console.error('에러 발생:', error);
      swal('오류', '시리얼 검증 중 오류가 발생했습니다.', 'error');
    }
  };

  const handleInputChange = (event, newValue) => {
    if (typeof newValue === 'string' && event === 'product_status') {
      setValues((prev) => ({ ...prev, product_status: newValue }));
      setError((prev) => ({ ...prev, product_status: '' }));
    } else {
      const { name, value } = event.target;
      setValues((prev) => ({ ...prev, [name]: value }));
      if (name !== 'remark') {
        setError((prev) => ({
          ...prev,
          [name]: value ? '' : '데이터를 입력하세요',
        }));
      }
    }
  };
  const handleDateChange = (newValue, columnId) => {
    const formattedDate = newValue
      ? dayjs(newValue).format('YYYY-MM-DDT00:00:00.000')
      : null;
    setValues((prev) => ({ ...prev, [columnId]: formattedDate }));
    if (columnId !== 'remark') {
      setError((prev) => ({
        ...prev,
        [columnId]: formattedDate ? '' : '데이터를 입력하세요',
      }));
    }
  };

  const handleProductLineChange = (event, newValue) => {
    if (!isSerialVerified) {
      setSearchForProductLine(newValue);
      if (newValue) {
        setValues((prev) => ({ ...prev, product_line: newValue.name }));
      } else {
        setValues((prev) => {
          const newValues = { ...prev };
          delete newValues.product_line;
          return newValues;
        });
      }
    }
  };

  const handleUseProductLineToggle = (event) => {
    if (!isSerialVerified) {
      setUseProductLine(event.target.checked);
      if (!event.target.checked) {
        setSearchForProductLine(null);
        setValues((prev) => ({ ...prev, product_line: '' }));
      }
    }
  };

  const handleReset = () => {
    setSerial('');
    setProductChecked(null);
    setValues({});
    setError({});
    setUseProductLine(false);
    setSearchForProductLine(null);
    setIsInputDisabled(false);
    setIsSerialVerified(false);
  };

  const handleSubmit = async () => {
    const newErrors = {};
    let isValid = true;

    [...baseColumns, ...(productChecked?.used_auxiliary_items || [])].forEach(
      (column) => {
        if (column.id !== 'remark' && !values[column.id]) {
          newErrors[column.id] = '데이터를 입력하세요';
          isValid = false;
        }
      }
    );

    setError(newErrors);

    if (!isValid) {
      swal('입력 오류', '모든 필수 필드를 입력해주세요.', 'error');
      return;
    }

    try {
      const dataToSubmit = {
        ...values,
      };

      const response = await regSerialItemInfo(dataToSubmit);
      if (response.status === 200) {
        swal({
          title: '성공',
          text: '시리얼 정보가 등록되었습니다.',
          icon: 'success',
          buttons: {
            confirm: '확인',
          },
          timer: 2000, // 2초 타이머 설정
        }).then(() => {
          window.location.reload(); // 확인 버튼을 누르거나 2초 후에 새로 고침
        });

        setTimeout(() => {
          window.location.reload(); // 2초 후 자동 새로 고침
        }, 2000);
      } else {
        swal('실패', '시리얼 정보 등록에 실패했습니다.', 'error');
      }
    } catch (error) {
      console.error('Error registering serial:', error);
      swal('오류', '시리얼 등록 중 오류가 발생했습니다.', 'error');
    }
  };

  return (
    <Dialog fullScreen open={true} onClose={onClose}>
      <DialogTitle>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='h6'>시리얼 정보 입력</Typography>
          <IconButton
            edge='end'
            color='inherit'
            onClick={onClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={useProductLine}
                onChange={handleUseProductLineToggle}
                disabled={isInputDisabled || isSerialVerified}
              />
            }
            label='제품라인 사용'
          />
          {useProductLine && (
            <Autocomplete
              options={filteredLineList}
              getOptionLabel={(option) => option.name}
              value={searchForProductLine}
              onChange={handleProductLineChange}
              renderInput={(params) => (
                <TextField {...params} label='제품라인 선택' />
              )}
              disabled={isInputDisabled || isSerialVerified}
            />
          )}
          <TextField
            label='시리얼 번호'
            value={serial}
            onChange={handleSerialChange}
            disabled={isInputDisabled || isSerialVerified}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {isSerialVerified ? (
                    <Button onClick={handleReset}>초기화</Button>
                  ) : (
                    <Button onClick={handleSerialSubmit}>찾기</Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {isSerialVerified && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {baseColumns.map((column) =>
                column.type === 'date' ? (
                  <DatePicker
                    key={column.id}
                    label={column.Header}
                    value={values[column.id] ? dayjs(values[column.id]) : null}
                    onChange={(newValue) =>
                      handleDateChange(newValue, column.id)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={!!error[column.id]}
                        helperText={error[column.id]}
                      />
                    )}
                  />
                ) : column.id === 'product_status' ? (
                  <Autocomplete
                    key={column.id}
                    options={productStatusOptions}
                    value={values[column.id] || null}
                    onChange={(event, newValue) =>
                      handleInputChange('product_status', newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={column.id}
                        label={column.Header}
                        error={!!error[column.id]}
                        helperText={error[column.id]}
                        fullWidth
                      />
                    )}
                  />
                ) : (
                  <TextField
                    key={column.id}
                    name={column.id}
                    label={column.Header}
                    value={values[column.id] || ''}
                    onChange={handleInputChange}
                    error={!!error[column.id]}
                    helperText={error[column.id]}
                    fullWidth
                  />
                )
              )}
              {productChecked?.used_auxiliary_items?.map((item) => (
                <TextField
                  key={item.id}
                  name={item.accessor} // 여기를 id에서 accessor로 변경
                  label={item.Header}
                  value={values[item.accessor] || ''}
                  onChange={handleInputChange}
                  error={!!error[item.accessor]}
                  helperText={error[item.accessor]}
                  fullWidth
                />
              ))}
            </LocalizationProvider>
          )}
          {isSerialVerified && (
            <Button variant='contained' onClick={handleSubmit} fullWidth>
              등록
            </Button>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AddSerialDialog;
