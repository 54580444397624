import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Stack,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Visibility, VisibilityOff, Phone, Lock } from '@mui/icons-material';

import Logo from '../../img/logibear.png';
import { useNavigate } from 'react-router-dom';
import CustomizedSnackbars from '../../components/CustomizedSnackbars/CustomizedSnackbars';
import { useAuth } from '../../hooks/useAuth';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 25,
    backgroundColor: '#f5f5f5',
    '&.Mui-focused': {
      backgroundColor: '#ffffff',
    },
  },
}));

const LoginButton = styled(Button)(({ theme }) => ({
  borderRadius: 25,
  padding: '12px 0',
  fontSize: '1rem',
  fontWeight: 'bold',
  textTransform: 'none',
}));

const Signin = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ criteriaMode: 'all' });

  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState('info');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const onSubmit = async (data) => {
    const result = await login(data);
    if (result.success) {
      setMsg(result.message);
      setSeverity('success');
      navigate('/');
      window.location.reload();
    } else {
      setMsg(result.message);
      switch (result.message) {
        case '로그인에 실패하였습니다. 잠시후 다시 로그인해주세요':
          setSeverity('error');
          break;
        case '로그인 계정정보를 확인해주세요':
        case '관리자에게 계정 허가를 요청하세요.':
        case '등록되지 않은 계정입니다. 계정발급을 요청해주세요.':
          setSeverity('warning');
          break;
        default:
          setSeverity('error');
          break;
      }
    }
    setSnackbarOpen(true);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        padding: '0 20px',
      }}
    >
      <Box
        component='img'
        src={Logo}
        sx={{ width: '200px', marginBottom: '2rem' }}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '100%', maxWidth: '350px' }}
      >
        <Stack spacing={3}>
          <StyledTextField
            fullWidth
            id='phone'
            placeholder='휴대전화번호'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Phone color='action' />
                </InputAdornment>
              ),
            }}
            {...register('phone', {
              required: '필수입력값입니다',
              pattern: {
                value: /^[0-9]{11}$/,
                message: "'-'기호를 제외한 11자리 숫자를 입력해주세요",
              },
            })}
            error={!!errors.phone}
            helperText={errors.phone?.message}
          />
          <StyledTextField
            fullWidth
            id='password'
            type={showPassword ? 'text' : 'password'}
            placeholder='비밀번호'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Lock color='action' />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...register('password', {
              required: '필수입력값입니다',
              minLength: { value: 8, message: '8자 이상 입력해주세요' },
              maxLength: { value: 15, message: '15자 이하로 입력해주세요' },
            })}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
          <LoginButton
            variant='contained'
            color='primary'
            type='submit'
            fullWidth
          >
            로그인
          </LoginButton>
        </Stack>
      </form>

      <CustomizedSnackbars
        msg={msg}
        severity={severity}
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Signin;
