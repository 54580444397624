import { CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';

const CircularBackground = ({ message }) => {
  return (
    <Stack
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '200vw',
        height: '200vh',
        backgroundColor: '#F8F8F8',
        opacity: '80%',
        zIndex: '9999',
      }}
    >
      <Stack
        alignItems={'center'}
        sx={{ position: 'absolute', top: '20%', left: 'calc(30% - 300px)' }}
      >
        <CircularProgress
          size={100}
          style={{
            color: '#377CAE',
            zIndex: '9999',
          }}
        />
        <Typography>{message}</Typography>
      </Stack>
    </Stack>
  );
};

export default CircularBackground;
