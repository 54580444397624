import axios from 'axios';
import { CONFIG } from '../../config';

axios.defaults.withCredentials = true;

export const client = axios.create({
  validateStatus: function (status) {
    return status < 500;
  },
  baseURL: CONFIG.API_BASE_URL,
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
