import { styled, Typography } from '@mui/material';
import { errRed } from '../../Style/Colors/Colors';

export const ErrText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  fontWeight: '500',
  fontSize: '0.8rem',
  marginTop: '30px',
  right: '10px',
  color: errRed,
}));
