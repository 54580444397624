import React from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';

const MenuWrapper = ({ open, menu, onItemClick }) => {
  return (
    <List>
      {menu.map((item, index) => (
        <ListItem key={index} disablePadding sx={{ display: 'block' }}>
          <Link
            to={item.link}
            style={{ textDecoration: 'none', color: 'inherit' }}
            onClick={onItemClick}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'white',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                sx={{ opacity: open ? 1 : 0, color: 'white' }}
              />
            </ListItemButton>
          </Link>
        </ListItem>
      ))}
    </List>
  );
};

export default MenuWrapper;
