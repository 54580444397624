import Input from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import { exBlue, formGray, inputGray } from '../../Style/Colors/Colors';

export const StyledInput = styled(Input)(({ theme }) => ({
  borderRadius: '4px',
  padding: '0 1rem',
  border: `2px solid ${formGray}`,
  '&:hover': {
    border: `2px solid ${exBlue}`,
  },
  '&.Mui-focused': {
    border: `2px solid ${exBlue}`,
    backgroundColor: inputGray,
  },
}));

export const DisabledInput = styled(Input)(({ theme }) => ({
  marginTop: '16px',
  padding: '0 1rem',
  border: `2px solid ${formGray}`,
  borderRadius: '4px',
  backgroundColor: '#E0E0E0',
}));
