import React, { useCallback, useRef } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Box,
} from '@mui/material';

const SerialList = ({
  serialList,
  onSerialSelect,
  productLines,
  productFound,
  onLoadMore,
}) => {
  const observer = useRef();
  const lastSerialElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onLoadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [onLoadMore]
  );

  const getProductInfo = (serial) => {
    if (serial.product_line_id) {
      const productLine = productLines.find(
        (line) => line.id === Number(serial.product_line_id)
      );
      return productLine ? productLine.name : '알 수 없는 제품라인';
    } else {
      const product = productFound.find(
        (product) => product.product_code === serial.serial_number.slice(0, -11)
      );
      return product ? product.name : '알 수 없는 제품';
    }
  };

  return (
    <List>
      {serialList.map((serial, index) => (
        <React.Fragment key={serial.id}>
          <ListItem
            button
            onClick={() => onSerialSelect(serial)}
            ref={index === serialList.length - 1 ? lastSerialElementRef : null}
          >
            <ListItemText
              primary={
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Typography
                    variant='body1'
                    component='span'
                    color='text.primary'
                    fontWeight='bold'
                  >
                    {serial.serial_number}
                  </Typography>
                  <Typography
                    variant='body2'
                    component='span'
                    color='primary'
                    fontWeight='medium'
                  >
                    {getProductInfo(serial)}
                  </Typography>
                </Box>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component='span'
                    variant='body2'
                    color='text.secondary'
                  >
                    {`제품S/N: ${serial.product_serial_number || 'N/A'}`}
                  </Typography>
                  <br />
                  <Typography
                    component='span'
                    variant='body2'
                    color='text.secondary'
                  >
                    {`상태: ${serial.product_status || 'N/A'}`}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          {index < serialList.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default SerialList;
