import React, { useState } from "react";
import {
  Container,
  Paper,
  Tabs,
  Tab,
  Typography,
  TextField,
  Button,
  Box,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Card,
  CardContent,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import QrCodeIcon from "@mui/icons-material/QrCode";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { QrReader } from "react-qr-reader";
import sweetAlert from "sweetalert";
import { useNavigate } from "react-router-dom";

const GS1_AIS = {
  "01": "상품식별코드(GTIN)",
  10: "로트번호",
  11: "생산일자",
  13: "포장일자",
  15: "최적품질유지기한",
  17: "사용기한",
  21: "일련번호",
  310: "순중량(kg)",
  37: "수량",
  3100: "순중량(kg)",
  3101: "순중량(kg)",
  3102: "순중량(kg)",
  3103: "순중량(kg)",
  3104: "순중량(kg)",
  3105: "순중량(kg)",
  3106: "순중량(kg)",
  // 추가 GS1 속성들...
};

const encodeGS1Data = (data) => {
  const params = Object.entries(data)
    .map(([ai, value]) => `${ai}=${encodeURIComponent(value)}`)
    .join("&");
  return `http://www.foodqr.kr/foodqr?${params}`;
};

const decodeGS1Data = (url) => {
  const params = new URLSearchParams(url.split("?")[1]);
  const decodedData = {};
  for (let [key, value] of params) {
    decodedData[key] = decodeURIComponent(value);
  }
  return decodedData;
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const FoodQR = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [gs1Data, setGs1Data] = useState({});
  const [qrValue, setQrValue] = useState("");
  const [scannedData, setScannedData] = useState(null);
  const [additionalAI, setAdditionalAI] = useState("");
  const [scannerType, setScannerType] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (ai, value) => {
    setGs1Data((prevData) => ({ ...prevData, [ai]: value }));
  };

  const generateQRCode = () => {
    const encodedUrl = encodeGS1Data(gs1Data);
    setQrValue(encodedUrl);
  };

  const handleScan = (result) => {
    if (result) {
      const decodedData = decodeGS1Data(result.text);
      setScannedData(decodedData);

      switch (scannerType) {
        case "consumer":
          window.location.href =
            "http://www.foodqr.kr/foodqr?PRD_NO=19760342001242";
          break;
        case "event":
          navigate("/event-page");
          break;
        case "seller":
          sweetAlert({
            title: "경고",
            text: "소비기한이 경과되어 출고할 수 없습니다.",
            icon: "warning",
          });
          break;
        default:
          sweetAlert({
            title: "오류",
            text: "스캐너 유형을 선택해주세요.",
            icon: "error",
          });
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={3} sx={{ borderRadius: 2, overflow: "hidden" }}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab icon={<QrCodeIcon />} label="QR 코드 생성" />
            <Tab icon={<CameraAltIcon />} label="QR 코드 스캔" />
          </Tabs>
          <Box p={4}>
            {activeTab === 0 ? (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom color="primary">
                    GS1 QR 코드 생성
                  </Typography>
                  {Object.entries(GS1_AIS)
                    .slice(0, 5)
                    .map(([ai, label]) => (
                      <TextField
                        key={ai}
                        label={`${label} (AI: ${ai})`}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={(e) => handleInputChange(ai, e.target.value)}
                      />
                    ))}
                  <Box mt={2}>
                    <FormControl fullWidth>
                      <InputLabel>추가 GS1 속성</InputLabel>
                      <Select
                        value={additionalAI}
                        onChange={(e) => setAdditionalAI(e.target.value)}
                      >
                        {Object.entries(GS1_AIS)
                          .slice(5)
                          .map(([ai, label]) => (
                            <MenuItem key={ai} value={ai}>
                              {label} (AI: {ai})
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  {additionalAI && (
                    <TextField
                      label={`${GS1_AIS[additionalAI]} (AI: ${additionalAI})`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      onChange={(e) =>
                        handleInputChange(additionalAI, e.target.value)
                      }
                    />
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={generateQRCode}
                    sx={{ mt: 2 }}
                  >
                    QR 코드 생성
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {qrValue && (
                    <Card elevation={2} sx={{ mt: { xs: 2, md: 0 } }}>
                      <CardContent>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <QRCodeSVG value={qrValue} size={200} />
                          <Typography
                            variant="body2"
                            mt={2}
                            textAlign="center"
                            sx={{ wordBreak: "break-all" }}
                          >
                            {qrValue}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Box>
                <Typography variant="h5" gutterBottom color="primary">
                  GS1 QR 코드 스캔
                </Typography>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>스캐너 유형 선택</InputLabel>
                  <Select
                    value={scannerType}
                    onChange={(e) => setScannerType(e.target.value)}
                  >
                    <MenuItem value="consumer">소비자</MenuItem>
                    <MenuItem value="event">이벤트</MenuItem>
                    <MenuItem value="seller">판매점</MenuItem>
                  </Select>
                </FormControl>
                <QrReader
                  delay={300}
                  onError={handleError}
                  onResult={handleScan}
                  style={{ width: "100%" }}
                />
                {scannedData && (
                  <Card elevation={2} sx={{ mt: 3 }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom color="primary">
                        스캔된 데이터:
                      </Typography>
                      {Object.entries(scannedData).map(([ai, value]) => (
                        <Typography key={ai} variant="body1">
                          <strong>{GS1_AIS[ai] || ai}:</strong> {value}
                        </Typography>
                      ))}
                    </CardContent>
                  </Card>
                )}
              </Box>
            )}
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default FoodQR;
