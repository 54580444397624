import MaterialTable from '@material-table/core';
import { Dialog, Stack, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  deleteSerialEtcContent,
  getInventorySheetsInfo,
  getSerialInfo,
  getSerialsStatus,
  getUserinfo,
} from '../../apis/_axios/fetchData';
import CustomizedSnackbars from '../../components/CustomizedSnackbars/CustomizedSnackbars';
import { InventoryReceipt } from '../../components/Receipt/InventoryReceipt';
import { classifyTypes } from '../../lib/ClassifyTypes/classifyTypes';
import { backgroundblue } from '../../Style/Colors/Colors';
import { ManageSerialDialog } from './Dialogs/ManageSerialDialog';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import swal from 'sweetalert';
import * as XLSX from 'xlsx';
import { SearchProductDialog } from '../../components/SearchElement/SearchProductDialog';

export const ManageSerials = ({ isMobile, handleTitle }) => {
  const [user, setUser] = useState();
  useEffect(() => {
    getUserinfo().then((res) => setUser(res.data.user_info[0]));
  }, []);
  useEffect(() => {
    handleTitle({ titleName: '시리얼별 입출내역', user: user });
  }, [user]);

  //스낵바UI이벤트
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [msg, setMsg] = useState();
  const [severity, setSeverity] = useState();
  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    handleSerialSearch();
    if (reason === 'escapeKeyDown') {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleSnackbarContents = (msg, severity) => {
    setMsg(msg);
    setSeverity(severity);
  };
  // 시리얼 관리속성편집 Dialog
  const [isSerialMngDialogOpen, setIsSerialMngDialogOpen] = useState(false);
  const handleSerialMngDialogOpen = () => {
    setIsSerialMngDialogOpen(true);
  };
  const handleSerialMngDialogClose = () => {
    setIsSerialMngDialogOpen(false);
  };
  //시리얼 관리속성 컨텐츠 삭제
  const handleDelete = async (data) => {
    swal({
      title: '경고',
      text: '선택하신 데이터를 정말 삭제하시겠습니까?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willCheck) => {
      if (willCheck) {
        goDelete(data);
      }
    });
    // if (window.confirm('선택하신 데이터를 정말 삭제하시겠습니까?')) {
    //   const response = await deleteSerialEtcContent(data);
    //   if (response.status === 200) {
    //     handleSnackbarContents('삭제하였습니다.', 'success');
    //     handleSnackbarOpen();
    //     handleSerialMngDialogClose();
    //   } else {
    //     handleSnackbarContents('삭제 실패. 잠시후 다시 시도해주세요.', 'error');
    //     handleSnackbarOpen();
    //   }
    // }
  };
  //완전삭제
  const goDelete = async (data) => {
    const response = await deleteSerialEtcContent(data);
    if (response.status === 200) {
      handleSnackbarContents('삭제하였습니다.', 'success');
      handleSnackbarOpen();
      handleSerialMngDialogClose();
    } else {
      handleSnackbarContents('삭제 실패. 잠시후 다시 시도해주세요.', 'error');
      handleSnackbarOpen();
    }
  };

  // 시리얼 이력조회 Dialog
  const [isSerialHistoryDialogOpen, setIsSerialHistoryDialogOpen] =
    useState(false);
  const [selectedSheet, setSelectedSheet] = useState();
  const handleSerialHistoryDialogOpen = () => {
    setIsSerialHistoryDialogOpen(true);
  };
  const handleSerialHistoryDialogClose = () => {
    setIsSerialHistoryDialogOpen(false);
  };
  const handleSelectedRow = async (rowData) => {
    if (
      rowData.type === 'inbound' ||
      rowData.type === 'outbound' ||
      rowData.type === 'generate' ||
      rowData.type === 'new'
    ) {
      const result = await getInventorySheetsInfo({
        sheet_id: rowData.content,
      });
      setSelectedSheet({
        id: rowData.content,
        document_num: result.data.message[0].sheet__document_num,
        date: result.data.message[0].sheet__date,
        type: result.data.message[0].sheet__type,
        user__name: result.data.message[0].sheet__user__name,
        company__name: result.data.message[0].sheet__company__name,
        etc: result.data.message[0].sheet__etc,
        fromSerialManageMenu: true,
      });
      handleSerialHistoryDialogOpen();
    } else if (rowData.type === 'used') {
      const result = await getInventorySheetsInfo({
        sheet_id: rowData.content,
      });
      const relatedResult = await getInventorySheetsInfo({
        sheet_id: result.data.message[0].sheet__related_sheet_id,
      });
      setSelectedSheet({
        id: relatedResult.data.message[0].sheet_id,
        document_num: relatedResult.data.message[0].sheet__document_num,
        date: relatedResult.data.message[0].sheet__date,
        type: relatedResult.data.message[0].sheet__type,
        user__name: relatedResult.data.message[0].sheet__user__name,
        company__name: relatedResult.data.message[0].sheet__company__name,
        etc: relatedResult.data.message[0].sheet__etc,
        fromSerialManageMenu: true,
      });
      handleSerialHistoryDialogOpen();
    }
  };

  //시리얼정보 불러오기
  const [serialInfo, setSerialInfo] = useState();
  const [searchWord, setSearchWord] = useState();
  const serialSearchText = useRef();
  const handleSerialSearch = async () => {
    if (serialSearchText.current.value.length === 0) {
      swal(
        '실패',
        '공백을 제외한 1자 이상의 시리얼번호를 입력해주세요',
        'error'
      );
    } else {
      const serialInfoFinded = await getSerialInfo(
        serialSearchText.current.value
      );
      if (serialInfoFinded.status === 200) {
        setSearchWord(serialSearchText.current.value);
        setSerialInfo(serialInfoFinded.data.message);
      } else {
        swal(
          '실패',
          `${serialSearchText.current.value} 의 시리얼 이력이 없습니다.`,
          'error'
        );
        setSerialInfo();
      }
    }
  };
  //시리얼 클릭조회시 init
  const location = useLocation();
  useEffect(() => {
    if (location.state !== null) {
      setSearchWord(location.state.serial);
      getSerialInfo(location.state.serial).then((res) =>
        setSerialInfo(res.data.message)
      );
      handleSerialHistoryDialogClose();
    }
  }, [location]);
  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, [serialInfo]);

  //시리얼 내려받기
  const [isSearchProductDlgOpened, setIsSearchProductDlgOpened] =
    useState(false);
  const handleSearchProductDlgOpen = () => {
    setIsSearchProductDlgOpened(true);
  };
  const handleSearchProductDlgClose = () => {
    setIsSearchProductDlgOpened(false);
  };
  const [productInfo, setProductInfo] = useState([]);
  const selectProduct = (product) => {
    setProductInfo({
      productId: product.id,
      productName: product.name,
    });
  };
  const handleDownloadExcel = async () => {
    const response = await getSerialsStatus(productInfo.productId);
    if (response.status === 200) {
      const data = response.data.message;
      data.map((item) => {
        item.type = classifyTypes(item.type);
      });
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
      XLSX.writeFile(wb, `${productInfo.productName + '_시리얼'}.xlsx`);
    }
  };

  return (
    <Stack
      sx={{
        height: '100vh',
        paddingTop: '1rem',
        paddingRight: '32px',
        paddingLeft: '32px',
        backgroundColor: backgroundblue,
      }}
    >
      <SearchProductDialog
        handleSearchProductDlgClose={handleSearchProductDlgClose}
        isSearchProductDlgOpened={isSearchProductDlgOpened}
        selectProduct={selectProduct}
        movementType={''}
      />
      {snackbarOpen && (
        <CustomizedSnackbars
          msg={msg}
          severity={severity}
          snackbarOpen={snackbarOpen}
          handleSnackbarClose={handleSnackbarClose}
        />
      )}
      {isSerialHistoryDialogOpen && (
        <Dialog
          onClose={handleSerialHistoryDialogClose}
          open={isSerialHistoryDialogOpen}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '50%',
              },
            },
          }}
        >
          <InventoryReceipt
            user={user && user}
            selectedSheet={selectedSheet}
            handleDialogClose={handleSerialHistoryDialogClose}
          />
        </Dialog>
      )}
      {isSerialMngDialogOpen && (
        <Dialog
          onClose={handleSerialMngDialogClose}
          open={isSerialMngDialogOpen}
        >
          <ManageSerialDialog
            user={user}
            handleSerialMngDialogClose={handleSerialMngDialogClose}
            handleSnackbarContents={handleSnackbarContents}
            handleSnackbarOpen={handleSnackbarOpen}
            searchWord={searchWord}
          />
        </Dialog>
      )}
      <div
        className='pageHeader'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          background: '#fff',
          marginBottom: '8px',
          alignItems: 'center',
          padding: '16px',
          borderRadius: '4px',
          boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        }}
      >
        <Stack
          className='left'
          direction={'row'}
          spacing={5}
          alignItems='center'
        >
          <div className='inputGroup'>
            <input
              type='text'
              placeholder='시리얼번호를 입력해주세요'
              style={{
                borderRadius: '2px',
                border: '1px solid #c8cad0',
                height: '32px',
                marginRight: '4px',
                padding: '0 8px',
                fontWeight: 'normal !important',
              }}
              ref={serialSearchText}
              value={serialSearchText.target}
            />
            <button
              className='searchButton'
              style={{
                borderRadius: '2px',
                background: '#ededed',
                border: '1px solid #c8cad0',
                height: '32px',
                padding: '0 16px',
                cursor: 'pointer',
              }}
              onClick={handleSerialSearch}
            >
              검색
            </button>
          </div>
          {serialInfo && (
            <Stack direction={'row'} spacing={1}>
              <span style={{ color: '#3271ff', fontWeight: 600 }}>
                '{searchWord}'
              </span>
              <span>로 검색한 결과입니다</span>
            </Stack>
          )}
        </Stack>
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent='center'
          alignItems={'center'}
        >
          {searchWord && (
            <button
              style={{
                border: 0,
                height: '40px',
                backgroundColor: '#3271ff',
                color: '#fff',
                fontSize: '15px',
                padding: '0 24px',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onClick={() => handleSerialMngDialogOpen()}
            >
              시리얼 이력추가
            </button>
          )}
          <div style={{ position: 'relative' }}>
            {productInfo && productInfo.productName ? (
              <TextField
                readOnly
                id='product_name'
                type='text'
                size='small'
                value={productInfo.productName}
                sx={{ cursor: 'pointer' }}
                onClick={handleSearchProductDlgOpen}
              />
            ) : (
              <TextField
                readOnly
                id='product_name'
                type='text'
                size='small'
                label='시리얼 다운로드'
                value={productInfo.productName}
                sx={{ cursor: 'pointer' }}
                onClick={handleSearchProductDlgOpen}
              />
            )}
          </div>
          {productInfo && productInfo.productId && (
            <button
              style={{
                border: 0,
                height: '40px',
                backgroundColor: '#267518 ',
                color: '#fff',
                fontSize: '15px',
                padding: '0 24px',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onClick={() => handleDownloadExcel()}
            >
              시리얼 내려받기
            </button>
          )}
        </Stack>
      </div>
      <MaterialTable
        columns={[
          {
            title: '속성',
            field: 'type',
            render: (rowData) => classifyTypes(rowData.type),
            headerStyle: {
              fontWeight: '600',
            },
          },
          {
            title: '담당자',
            field: 'user',
            headerStyle: {
              fontWeight: '600',
            },
          },
          {
            title: '상세내역',
            field: 'content',
            width: '35%',
            render: (rowData) =>
              rowData.type === 'inbound'
                ? ''
                : rowData.type === 'outbound'
                ? ''
                : rowData.type === 'new'
                ? ''
                : rowData.type === 'generate'
                ? ''
                : rowData.type === 'used'
                ? ''
                : rowData.content,
            headerStyle: {
              fontWeight: '600',
            },
          },
          {
            title: '작성일시',
            render: (rowData) => rowData.date + ' - ' + rowData.time,
            headerStyle: {
              fontWeight: '600',
            },
          },
          {
            title: '삭제',
            headerStyle: {
              fontWeight: '600',
            },
            width: '3%',
            render: (rowData) =>
              rowData.type === 'inbound' ? (
                ''
              ) : rowData.type === 'outbound' ? (
                ''
              ) : rowData.type === 'new' ? (
                ''
              ) : rowData.type === 'generate' ? (
                ''
              ) : rowData.type === 'used' ? (
                ''
              ) : (
                <DeleteForeverIcon onClick={() => handleDelete(rowData)} />
              ),
          },
        ]}
        data={serialInfo}
        onRowClick={(evt, selectedRow) => {
          handleSelectedRow(selectedRow);
        }}
        options={{
          pageSize: 15,
          pageSizeOptions: [15],
          padding: 'dense',
          showTitle: false,
          search: false,
          toolbar: false,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: '표시할 정보가 없습니다',
          },
        }}
      />
    </Stack>
  );
};
