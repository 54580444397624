import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { RegEtcContentsTab } from './ManageSerialTabs.jsx/RegEtcContentsTab';
import { SetEtcTitleTab } from './ManageSerialTabs.jsx/SetEtcTitleTab';

export const ManageSerialDialog = ({
  user,
  handleSerialMngDialogClose,
  handleSnackbarContents,
  handleSnackbarOpen,
  searchWord,
}) => {
  const [tabcontent, setTabcontent] = useState(0);
  const handleTabChange = (event, newTabcontent) => {
    setTabcontent(newTabcontent);
  };
  function TabPanel({ tabcontent, index, children, ...other }) {
    return (
      <div
        role='tabpanel'
        hidden={tabcontent !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabcontent === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <>
      <Tabs
        value={tabcontent}
        onChange={handleTabChange}
        sx={{ paddingLeft: '2rem' }}
      >
        {user && <Tab label='이력추가' />}
        {user && user.admin && <Tab label='이력항목 설정' />}
      </Tabs>
      <TabPanel tabcontent={tabcontent} index={0}>
        <RegEtcContentsTab
          handleSerialMngDialogClose={handleSerialMngDialogClose}
          handleSnackbarContents={handleSnackbarContents}
          handleSnackbarOpen={handleSnackbarOpen}
          searchWord={searchWord}
        />
      </TabPanel>
      <TabPanel tabcontent={tabcontent} index={1}>
        <SetEtcTitleTab
          handleSerialMngDialogClose={handleSerialMngDialogClose}
          handleSnackbarContents={handleSnackbarContents}
          handleSnackbarOpen={handleSnackbarOpen}
        />
      </TabPanel>
    </>
  );
};
