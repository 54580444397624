import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DoNotDisturbSharpIcon from '@mui/icons-material/DoNotDisturbSharp';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { visuallyHidden } from '@mui/utils';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const headCells = [
  {
    id: 'status',
    align: 'left',
    label: '상태',
  },
  {
    id: 'name',
    align: 'left',
    label: '성명',
  },
  {
    id: 'position',
    align: 'left',
    disablePadding: false,
    label: '직책/직급',
  },
  {
    id: 'team',
    align: 'left',
    disablePadding: false,
    label: '부서',
  },
  {
    id: 'phone',
    align: 'left',
    disablePadding: false,
    label: '연락처',
  },
  {
    id: 'email',
    align: 'left',
    disablePadding: false,
    label: '이메일',
  },
  {
    id: 'modUser',
    align: 'left',
    disablePadding: false,
    label: '정보수정',
  },
];

export default function ManageTotalUser({
  users,
  handleStatus,
  selected,
  handleSelected,
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const ROWS_PER_PAGE = 10;

  //레이블 헤드세팅
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontWeight: 700, minWidth: '4rem' }}>선택</TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ fontWeight: 700, minWidth: '6rem' }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  //레이블툴바 세팅(필터 및 관리자기능)
  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 && (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color='inherit'
            variant='subtitle1'
            component='div'
          >
            {numSelected} 개의 임직원 정보가 선택되었습니다.
          </Typography>
        )}

        {numSelected > 0 && (
          <>
            <Tooltip title='승인'>
              <Button
                color='success'
                onClick={() => handleStatus(selected, 'True')}
              >
                <CheckCircleSharpIcon />
              </Button>
            </Tooltip>
            <Tooltip title='사용정지'>
              <Button
                color='error'
                onClick={() => handleStatus(selected, 'False')}
              >
                <DoNotDisturbSharpIcon />
              </Button>
            </Tooltip>
          </>
        )}
      </Toolbar>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleClick = (event, phone) => {
    const selectedIndex = selected.indexOf(phone);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, phone);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    handleSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = (phone) => selected.indexOf(phone) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * ROWS_PER_PAGE - users.length) : 0;

  const navigate = useNavigate();
  const handleUserInfo = (user) => {
    navigate('/mypage/modUser', { state: user });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size={'small'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(users, getComparator(order, orderBy))
                .slice(
                  page * ROWS_PER_PAGE,
                  page * ROWS_PER_PAGE + ROWS_PER_PAGE
                )
                .map((user, index) => {
                  const isItemSelected = isSelected(user.phone);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          onClick={(event) => handleClick(event, user.phone)}
                          color='primary'
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align='left'
                        onClick={(event) => handleClick(event, user.phone)}
                      >
                        {user.status ? (
                          <span style={{ color: 'blue' }}>사용</span>
                        ) : (
                          <span style={{ color: 'red' }}>사용중지</span>
                        )}
                      </TableCell>
                      <TableCell
                        component='th'
                        id={labelId}
                        scope='row'
                        onClick={(event) => handleClick(event, user.phone)}
                      >
                        {user.name}
                      </TableCell>
                      <TableCell
                        align='left'
                        onClick={(event) => handleClick(event, user.phone)}
                      >
                        {user.position}
                      </TableCell>
                      <TableCell
                        align='left'
                        onClick={(event) => handleClick(event, user.phone)}
                      >
                        {user.team}
                      </TableCell>
                      <TableCell
                        align='left'
                        onClick={(event) => handleClick(event, user.phone)}
                      >
                        {user.phone.substr(0, 3)}-{user.phone.substr(3, 4)}-
                        {user.phone.substr(7, 4)}
                      </TableCell>
                      <TableCell
                        align='left'
                        onClick={(event) => handleClick(event, user.phone)}
                      >
                        {user.email}
                      </TableCell>
                      <TableCell align='left'>
                        <Button onClick={() => handleUserInfo(user)}>
                          <ManageAccountsIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component='div'
          count={users.length}
          rowsPerPage={ROWS_PER_PAGE}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
    </Box>
  );
}
