import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Chip,
  SwipeableDrawer,
  IconButton,
} from '@mui/material';
import {
  Warning,
  Inventory,
  TrendingDown,
  TrendingUp,
  Close,
} from '@mui/icons-material';
import { getTotalQuantity } from '../../apis/_axios/fetchData';

export const SafeStock = ({ handleTitle }) => {
  const [safeStockData, setSafeStockData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    handleTitle({ titleName: '안전재고 현황' });
    fetchSafeStockData();
  }, [handleTitle]);

  const fetchSafeStockData = async () => {
    try {
      const response = await getTotalQuantity();
      const filteredData = response.data.message
        .filter(
          (item) => item.quantity < item.safe_quantity && item.status !== false
        )
        .sort(
          (a, b) => a.quantity / a.safe_quantity - b.quantity / b.safe_quantity
        );
      setSafeStockData(filteredData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching safe stock data:', error);
      setLoading(false);
    }
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setOpenDrawer(true);
  };

  const getStockStatus = (quantity, safeQuantity) => {
    const ratio = quantity / safeQuantity;
    if (ratio <= 0.3)
      return { color: 'error', icon: <TrendingDown />, text: '긴급 보충 필요' };
    if (ratio <= 0.7)
      return { color: 'warning', icon: <Warning />, text: '보충 권장' };
    return { color: 'success', icon: <TrendingUp />, text: '양호' };
  };

  if (loading) {
    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress size={60} />
        <Typography variant='h6' style={{ marginTop: 20 }}>
          데이터를 불러오는 중입니다...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant='h5' gutterBottom>
        안전재고 미달 상품 ({safeStockData.length})
      </Typography>
      <List>
        {safeStockData.map((product) => {
          const status = getStockStatus(
            product.quantity,
            product.safe_quantity
          );
          return (
            <ListItem
              key={product.product_code}
              button
              onClick={() => handleProductClick(product)}
              sx={{
                mb: 2,
                borderRadius: 2,
                boxShadow: 1,
                '&:hover': { backgroundColor: 'action.hover' },
              }}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: status.color + '.light' }}>
                  <Inventory />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={product.product_name}
                secondary={
                  <React.Fragment>
                    <Typography
                      component='span'
                      variant='body2'
                      color='text.primary'
                    >
                      {product.product_code}
                    </Typography>
                    {' — '}
                    {product.product_group_name}
                  </React.Fragment>
                }
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Chip
                  icon={status.icon}
                  label={status.text}
                  color={status.color}
                  size='small'
                  sx={{ mb: 1 }}
                />
                <Typography variant='body2'>
                  {product.quantity} / {product.safe_quantity}
                </Typography>
              </Box>
            </ListItem>
          );
        })}
      </List>

      <SwipeableDrawer
        anchor='bottom'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
      >
        {selectedProduct && (
          <Box
            sx={{
              p: 3,
              maxWidth: '100%',
              width: '100%',
              maxHeight: '80vh',
              overflowY: 'auto',
            }}
          >
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              mb={2}
            >
              <Typography variant='h6'>
                {selectedProduct.product_name}
              </Typography>
              <IconButton onClick={() => setOpenDrawer(false)}>
                <Close />
              </IconButton>
            </Box>
            <Typography variant='body1' gutterBottom>
              <strong>제품 코드:</strong> {selectedProduct.product_code}
            </Typography>
            <Typography variant='body1' gutterBottom>
              <strong>그룹:</strong> {selectedProduct.product_group_name}
            </Typography>
            <Typography variant='body1' gutterBottom>
              <strong>현재 재고:</strong> {selectedProduct.quantity}
            </Typography>
            <Typography variant='body1' gutterBottom>
              <strong>안전 재고:</strong> {selectedProduct.safe_quantity}
            </Typography>
          </Box>
        )}
      </SwipeableDrawer>
    </Box>
  );
};

export default SafeStock;
