import React, { useState, useEffect, useCallback } from 'react';
import {
  Stack,
  Typography,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Grid,
  Paper,
  Divider,
  CircularProgress,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FilterList, ExpandMore, Close } from '@mui/icons-material';
import dayjs from 'dayjs';
import { getInventorySheetsInfo } from '../../apis/_axios/fetchData';
import { classifyTypes } from '../../lib/ClassifyTypes/classifyTypes';
import InfiniteScroll from 'react-infinite-scroll-component';

export const InventoryInfo = ({ handleTitle }) => {
  const [inventoryData, setInventoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [filters, setFilters] = useState({
    startDate: dayjs().subtract(1, 'month'),
    endDate: dayjs(),
    type: '',
    companyName: '',
    productName: '',
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const [summaryData, setSummaryData] = useState({
    inbound: 0,
    outbound: 0,
    generate: 0,
    used: 0,
    return: 0,
  });

  useEffect(() => {
    handleTitle({ titleName: '입출고현황' });
    fetchInventoryData();
  }, []);

  const fetchInventoryData = async () => {
    try {
      const response = await getInventorySheetsInfo({
        date_start: filters.startDate.format('YYYY-MM-DD'),
        date_end: filters.endDate.format('YYYY-MM-DD'),
        offset: 0,
        limit: 100000,
      });
      setInventoryData(response.data.message);
      applyFilters(response.data.message);
    } catch (error) {
      console.error('Error fetching inventory data:', error);
    }
  };

  const applyFilters = useCallback(
    (data) => {
      let result = data;
      if (filters.type) {
        result = result.filter((item) => item.sheet__type === filters.type);
      }
      if (filters.companyName) {
        result = result.filter((item) =>
          item.sheet__company__name
            .toLowerCase()
            .includes(filters.companyName.toLowerCase())
        );
      }
      if (filters.productName) {
        result = result.filter((item) =>
          item.product__name
            .toLowerCase()
            .includes(filters.productName.toLowerCase())
        );
      }
      setFilteredData(result);
      setDisplayedData(result.slice(0, 20));
      setHasMore(result.length > 20);
      calculateSummary(result);
    },
    [filters]
  );

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const calculateSummary = (data) => {
    const summary = {
      inbound: 0,
      outbound: 0,
      generate: 0,
      used: 0,
      return: 0,
    };
    data.forEach((item) => {
      summary[item.sheet__type] += item.quantity;
    });
    setSummaryData(summary);
  };

  const handleSearch = () => {
    fetchInventoryData();
    setIsFilterDialogOpen(false);
  };

  // const handleRemoveFilter = (filterKey) => {
  //   setFilters((prev) => ({ ...prev, [filterKey]: '' }));
  //   applyFilters(inventoryData);
  // };
  const handleRemoveFilter = (filterKey) => {
    setFilters((prev) => {
      const newFilters = { ...prev, [filterKey]: '' };
      applyFiltersWithNewFilters(newFilters); // 새로운 필터 상태로 applyFilters 호출
      return newFilters;
    });
  };

  const applyFiltersWithNewFilters = (newFilters) => {
    let result = inventoryData;
    if (newFilters.type) {
      result = result.filter((item) => item.sheet__type === newFilters.type);
    }
    if (newFilters.companyName) {
      result = result.filter((item) =>
        item.sheet__company__name
          .toLowerCase()
          .includes(newFilters.companyName.toLowerCase())
      );
    }
    if (newFilters.productName) {
      result = result.filter((item) =>
        item.product__name
          .toLowerCase()
          .includes(newFilters.productName.toLowerCase())
      );
    }
    setFilteredData(result);
    setDisplayedData(result.slice(0, 20));
    setHasMore(result.length > 20);
    calculateSummary(result);
  };
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsDrawerOpen(true);
  };

  const loadMore = () => {
    const currentLength = displayedData.length;
    const moreData = filteredData.slice(currentLength, currentLength + 20);
    setDisplayedData([...displayedData, ...moreData]);
    setHasMore(currentLength + 20 < filteredData.length);
  };

  const renderListItem = (item) => (
    <ListItem button onClick={() => handleItemClick(item)} key={item.id}>
      <ListItemText
        primary={item.product__name}
        secondary={
          <React.Fragment>
            <Typography component='span' variant='body2' color='text.primary'>
              {classifyTypes(item.sheet__type)} - {item.sheet__date}
            </Typography>
            <br />
            {`수량: ${item.quantity} - ${item.sheet__company__name}`}
          </React.Fragment>
        }
      />
      <Chip
        label={classifyTypes(item.sheet__type)}
        color={item.sheet__type === 'inbound' ? 'success' : 'error'}
        size='small'
      />
    </ListItem>
  );

  const activeFilters = Object.entries(filters).filter(
    ([key, value]) => value && key !== 'startDate' && key !== 'endDate'
  );

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        {activeFilters.length > 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            {activeFilters.map(([key, value]) => (
              <Chip
                key={key}
                label={`${
                  key === 'type'
                    ? '유형'
                    : key === 'companyName'
                    ? '거래처'
                    : '제품명'
                }: ${value}`}
                onDelete={() => handleRemoveFilter(key)}
                color='primary'
                variant='outlined'
              />
            ))}
          </Box>
        )}
        <Box
          sx={{
            ml: '10px',
          }}
        >
          <Chip
            label={`기간: ${filters.startDate.format(
              'YYYY-MM-DD'
            )} ~ ${filters.endDate.format('YYYY-MM-DD')}`}
            color='primary'
            variant='outlined'
          />
        </Box>

        <IconButton onClick={() => setIsFilterDialogOpen(true)}>
          <FilterList />
        </IconButton>
      </Box>

      {activeFilters.length > 0 && (
        <Card elevation={3}>
          <CardHeader
            title='요약 정보'
            action={
              <IconButton
                onClick={handleExpandClick}
                aria-expanded={isExpanded}
                aria-label='show more'
              >
                <ExpandMore />
              </IconButton>
            }
          />
          <Collapse in={isExpanded} timeout='auto' unmountOnExit>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant='subtitle2'>입고</Typography>
                    <Typography variant='h6' color='primary'>
                      {summaryData.inbound}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant='subtitle2'>출고</Typography>
                    <Typography variant='h6' color='error'>
                      {summaryData.outbound}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant='subtitle2'>생산</Typography>
                    <Typography variant='h6' color='primary'>
                      {summaryData.generate}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant='subtitle2'>소모</Typography>
                    <Typography variant='h6' color='error'>
                      {summaryData.used}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant='subtitle2'>환원</Typography>
                    <Typography variant='h6' color='error'>
                      {summaryData.return}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant='subtitle1' fontWeight='bold'>
                      기간 중 소계 ({filters.startDate.format('YYYY-MM-DD')} ~{' '}
                      {filters.endDate.format('YYYY-MM-DD')})
                    </Typography>
                    <Typography variant='h5' fontWeight='bold'>
                      {summaryData.inbound +
                        summaryData.generate -
                        (summaryData.outbound +
                          summaryData.used +
                          summaryData.return)}{' '}
                      EA
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
      )}

      <InfiniteScroll
        dataLength={displayedData.length}
        next={loadMore}
        hasMore={hasMore}
        loader={
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            height='100vh'
          >
            <CircularProgress size={60} />
            <Typography variant='h6' style={{ marginTop: 20 }}>
              데이터를 불러오는 중입니다...
            </Typography>
          </Box>
        }
      >
        <List>{displayedData.map(renderListItem)}</List>
      </InfiniteScroll>

      <Drawer
        anchor='bottom'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        {selectedItem && (
          <Box sx={{ p: 3 }}>
            <Typography variant='h5' gutterBottom>
              {selectedItem.product__name}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant='subtitle2'>문서번호</Typography>
                <Typography variant='body1'>
                  {selectedItem.sheet__document_num}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2'>유형</Typography>
                <Chip
                  label={classifyTypes(selectedItem.sheet__type)}
                  color={
                    selectedItem.sheet__type === 'inbound' ? 'success' : 'error'
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2'>날짜</Typography>
                <Typography variant='body1'>
                  {selectedItem.sheet__date}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2'>수량</Typography>
                <Typography variant='body1' fontWeight='bold'>
                  {selectedItem.quantity}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2'>거래처</Typography>
                <Typography variant='body1'>
                  {selectedItem.sheet__company__name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2'>담당자</Typography>
                <Typography variant='body1'>
                  {selectedItem.sheet__user__name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>비고</Typography>
                <Paper variant='outlined' sx={{ p: 1, mt: 1 }}>
                  <Typography variant='body2'>
                    {selectedItem.sheet__etc || '없음'}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
      </Drawer>

      <Dialog
        open={isFilterDialogOpen}
        onClose={() => setIsFilterDialogOpen(false)}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>
          필터 설정
          <IconButton
            aria-label='close'
            onClick={() => setIsFilterDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DatePicker
                label='시작일'
                value={filters.startDate}
                onChange={(newValue) =>
                  handleFilterChange('startDate', newValue)
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <DatePicker
                label='종료일'
                value={filters.endDate}
                onChange={(newValue) => handleFilterChange('endDate', newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <FormControl fullWidth>
                <InputLabel>유형</InputLabel>
                <Select
                  value={filters.type}
                  onChange={(e) => handleFilterChange('type', e.target.value)}
                  label='유형'
                >
                  <MenuItem value=''>전체</MenuItem>
                  <MenuItem value='inbound'>입고</MenuItem>
                  <MenuItem value='outbound'>출고</MenuItem>
                  <MenuItem value='generate'>생산</MenuItem>
                  <MenuItem value='used'>사용</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label='거래처'
                fullWidth
                value={filters.companyName}
                onChange={(e) =>
                  handleFilterChange('companyName', e.target.value)
                }
              />
              <TextField
                label='제품명'
                fullWidth
                value={filters.productName}
                onChange={(e) =>
                  handleFilterChange('productName', e.target.value)
                }
              />
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsFilterDialogOpen(false)}>취소</Button>
          <Button onClick={handleSearch} variant='contained' color='primary'>
            검색
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
