import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  btnBlue,
  btnGray,
  exBlue,
  normalWhite,
} from '../../Style/Colors/Colors';

const fullWidth = '100%';
const commonBorderRadius = '4px';
const commonPadding = '6px 16px';
const commonFontSize = '1rem';
const boldFontWeight = 700;

export const GrayButton = styled(Button)(({ theme }) => ({
  width: fullWidth,
  padding: commonPadding,
  borderRadius: commonBorderRadius,
  fontWeight: boldFontWeight,
  fontSize: commonFontSize,
  color: normalWhite,
  backgroundColor: btnGray,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: btnBlue,
    boxShadow: 'none',
  },
  '&.Mui-focused': {},
}));

export const SimpleRoundedButton = styled(Button)(({ theme }) => ({
  width: fullWidth,
  borderRadius: '50px',
  padding: '0px 15px 0px 15px',
  background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF)',
  border: '1px solid rgba(26, 26, 26, 1)',
  '&:hover': {
    backgroundColor: btnBlue,
  },
  '&.Mui-focused': {},
}));

export const BlueButton = styled(Button)(({ theme }) => ({
  width: fullWidth,
  padding: commonPadding,
  borderRadius: commonBorderRadius,
  fontWeight: boldFontWeight,
  fontSize: commonFontSize,
  color: normalWhite,
  backgroundColor: exBlue,
  '&:hover': {
    backgroundColor: btnBlue,
  },
  '&.Mui-focused': {},
}));

export const SampleBtn = styled(Button)(({ theme }) => ({
  width: fullWidth,
  padding: commonPadding,
  borderRadius: commonBorderRadius,
  fontWeight: boldFontWeight,
  fontSize: commonFontSize,
  color: normalWhite,
  backgroundColor: exBlue,
  '&:hover': {
    backgroundColor: btnBlue,
  },
  '&.Mui-focused': {},
}));

export const ScannerButton = styled(Button)(({ theme }) => ({
  width: '5%',
  padding: '0.5rem',
  borderRadius: '8px',
  border: '1px solid black',
  fontWeight: boldFontWeight,
  fontSize: '0.5rem',
  color: normalWhite,
  backgroundColor: btnGray,
  '&:hover': {
    backgroundColor: btnBlue,
  },
  '&.Mui-focused': {},
}));

export const BlueSquareButton = styled(Button)(({ theme }) => ({
  border: 0,
  height: '40px',
  backgroundColor: '#3271ff',
  color: '#fff',
  fontSize: '15px',
  padding: '0 24px',
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
    border: 0,
    height: '40px',
    backgroundColor: '#3271ff',
    color: '#fff',
    fontSize: '15px',
    padding: '0 24px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
}));
