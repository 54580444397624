import React, { useState, useEffect, useCallback } from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Chip,
  SwipeableDrawer,
  CircularProgress,
  FormControlLabel,
  Switch,
  Tooltip,
  Button,
} from '@mui/material';
import { Search, Sort, ExpandMore } from '@mui/icons-material';
import { getTotalQuantity } from '../../apis/_axios/fetchData';
import { ProductInfoDialog } from './component/ProductInfoDialog';

export const StockInfo = ({ handleTitle }) => {
  const [stockData, setStockData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showInactiveProducts, setShowInactiveProducts] = useState(false);
  const [displayCount, setDisplayCount] = useState(20);

  useEffect(() => {
    handleTitle({ titleName: '재고현황' });
    fetchStockData();
  }, [handleTitle]);

  const fetchStockData = async () => {
    setLoading(true);
    try {
      const res = await getTotalQuantity();
      setStockData(res.data.message);
    } catch (error) {
      console.error('Error fetching stock data:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: '제품정보',
        accessor: 'product_name',
        width: '40%',
        Cell: ({ row }) => (
          <Tooltip
            title={`${row.original.product_name} (${row.original.product_code})`}
          >
            <Box>
              <Typography variant='subtitle2' noWrap>
                {row.original.product_name}
              </Typography>
              <Typography variant='caption' color='textSecondary' noWrap>
                {row.original.product_code}
              </Typography>
            </Box>
          </Tooltip>
        ),
      },
      {
        Header: '수량',
        accessor: 'quantity',
        width: '20%',
        Cell: ({ value, row }) => (
          <Box>
            <Typography variant='body2'>{value}</Typography>
            {row.original.quantity < row.original.safe_quantity && (
              <Chip size='small' label='부족' color='error' />
            )}
          </Box>
        ),
      },
      {
        Header: '그룹',
        accessor: 'product_group_name',
        width: '25%',
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <Typography noWrap>{value}</Typography>
          </Tooltip>
        ),
      },
      {
        Header: '상태',
        accessor: 'status',
        width: '15%',
        Cell: ({ value }) => (
          <Chip
            size='small'
            label={value ? '사용중' : '사용중지'}
            color={value ? 'success' : 'default'}
          />
        ),
      },
    ],
    []
  );

  const filteredData = React.useMemo(() => {
    return showInactiveProducts
      ? stockData
      : stockData.filter((item) => item.status !== false);
  }, [stockData, showInactiveProducts]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: filteredData,
    },
    useGlobalFilter,
    useSortBy
  );

  const handleRowClick = useCallback((product) => {
    setSelectedProduct(product);
    setIsDrawerOpen(true);
  }, []);

  const loadMore = useCallback(() => {
    setDisplayCount((prevCount) => prevCount + 20);
  }, []);

  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress />
        <Typography variant='h6' style={{ marginLeft: 20 }}>
          데이터를 불러오는 중입니다...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <TextField
          variant='outlined'
          placeholder='제품 검색...'
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value)}
          InputProps={{
            startAdornment: <Search color='action' />,
          }}
          sx={{ width: '70%' }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={showInactiveProducts}
              onChange={() => setShowInactiveProducts(!showInactiveProducts)}
            />
          }
          label={
            <Typography sx={{ fontSize: '0.75rem' }}>사용중지품목</Typography>
          }
          labelPlacement='top'
          sx={{ alignItems: 'flex-start' }}
        />
      </Box>

      <Box sx={{ overflowX: 'auto' }}>
        <table
          {...getTableProps()}
          style={{
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: 0,
          }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      padding: '10px',
                      textAlign: 'left',
                      borderBottom: '2px solid #e0e0e0',
                      width: column.width,
                    }}
                  >
                    {column.render('Header')}
                    <IconButton size='small'>
                      <Sort />
                    </IconButton>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.slice(0, displayCount).map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => handleRowClick(row.original)}
                  style={{ cursor: 'pointer' }}
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #e0e0e0',
                        width: cell.column.width,
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>

      {displayCount < rows.length && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            variant='outlined'
            onClick={loadMore}
            startIcon={<ExpandMore />}
          >
            더 보기
          </Button>
        </Box>
      )}

      <SwipeableDrawer
        anchor='bottom'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onOpen={() => setIsDrawerOpen(true)}
      >
        {selectedProduct && (
          <ProductInfoDialog
            selectedProduct={{ data: { message: [selectedProduct] } }}
            stockQtt={stockData}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        )}
      </SwipeableDrawer>
    </Box>
  );
};

export default StockInfo;
