import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, IconButton, Stack } from '@mui/material';
import FilterListIcon from '@mui/icons-material/Filter';
import AddIcon from '@mui/icons-material/Add';
import SearchBar from './SearchBar/SearchBar';
import SerialList from './SerialList/SerialList';
import FilterDialog from './FilterDialog/FilterDialog';
import AddSerialDialog from './AddSerialDialog/AddSerialDialog';
import SerialDetailDialog from './SerialDetailDialog/SerialDetailDialog';
import {
  getSerialItemInfo,
  getSerialManagedItem,
  getSerialManagedProductLine,
  getSpecificProductInfo,
} from '../../apis/_axios/fetchData';
import useColumnsMapper from '../../hooks/useColumnsMapper';
const EnhancedSerialManagerForMobile = ({ handleTitle }) => {
  const [serialList, setSerialList] = useState([]);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [selectedSerial, setSelectedSerial] = useState(null);
  const [filterOptions, setFilterOptions] = useState({});
  const [productLines, setProductLines] = useState([]);
  // ★ 추가된 상태
  const [productFound, setProductFound] = useState([]);
  const columnsArray = useColumnsMapper();
  // ★
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  //시리얼검색
  const [searchTerm, setSearchTerm] = useState('');
  //복사를 위한 상태
  const [copiedSerialData, setCopiedSerialData] = useState(null);

  useEffect(() => {
    handleTitle({ titleName: '시리얼 관리' });
    fetchProductLines();
    fetchInitialData();
  }, []);

  const fetchSerialList = useCallback(
    async (newPage = 1, options = filterOptions) => {
      try {
        const response = await getSerialItemInfo({
          status: 1,
          offset: (newPage - 1) * 20,
          limit: 20,
          ...options,
        });

        if (newPage === 1) {
          setSerialList(response.data.data);
        } else {
          setSerialList((prevList) => [...prevList, ...response.data.data]);
        }

        setHasMore(response.data.data.length === 20);
        setPage(newPage);
      } catch (error) {
        console.error('Error fetching serial list:', error);
      }
    },
    []
  );
  const handleLoadMore = useCallback(() => {
    if (hasMore) {
      fetchSerialList(page + 1);
    }
  }, [fetchSerialList, hasMore, page]);

  //복사
  const handleCopy = (data) => {
    setCopiedSerialData(data);
    setIsDetailDialogOpen(false);
    setIsAddDialogOpen(true);
  };
  // ★ 추가된 함수
  const fetchInitialData = async () => {
    try {
      const [lineListResponse, serialManagedItemResponse] = await Promise.all([
        getSerialManagedProductLine(),
        getSerialManagedItem(),
      ]);
      setProductLines(lineListResponse.data);

      const filteredData = serialManagedItemResponse.data.filter(
        (product) => product.status === true
      );

      const productPromises = filteredData.map((product) =>
        getSpecificProductInfo(`product_code=${product.product_code}`)
      );

      const productResponses = await Promise.all(productPromises);

      const mappedResult = filteredData.map((product, index) => {
        const specificProductInfo = productResponses[index].data.message[0];
        const mappedItems = product.used_auxiliary_items.map((item) => {
          const column = columnsArray.find((column) => column.id === item);
          if (column) {
            return {
              id: item,
              Header: column.Header,
              accessor: item,
            };
          }
          return item;
        });

        return {
          ...product,
          ...specificProductInfo,
          used_auxiliary_items: mappedItems,
          serial_managed_item_id: product.id,
        };
      });

      setProductFound(mappedResult);
      fetchSerialList();
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };
  // ★
  const fetchProductLines = async () => {
    try {
      const response = await getSerialManagedProductLine();
      setProductLines(response.data);
    } catch (error) {
      console.error('Error fetching product lines:', error);
    }
  };

  const handleAddOpen = () => {
    setIsAddDialogOpen(true);
  };

  const handleSerialSelect = (serial) => {
    setSelectedSerial(serial);
  };

  const handleSerialAdd = () => {
    fetchSerialList();
  };

  const handleSerialUpdate = () => {
    fetchSerialList();
  };
  const handleSearchChange = useCallback((value) => {
    setSearchTerm(value);
  }, []);

  const handleSearch = useCallback(
    async (term) => {
      // 새로운 필터 옵션 객체 생성
      const newFilterOptions = { ...filterOptions, serial_number: term };

      // 필터 옵션 상태 업데이트
      setFilterOptions(newFilterOptions);

      // 새로운 필터 옵션으로 시리얼 리스트 가져오기
      await fetchSerialList(1, newFilterOptions);
    },
    [fetchSerialList, filterOptions]
  );

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant='h6' gutterBottom>
        시리얼 관리
      </Typography>
      <Stack direction='row' spacing={1} alignItems='center' sx={{ mb: 2 }}>
        <SearchBar
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          onSearch={() => handleSearch(searchTerm)}
        />
        {/* <IconButton onClick={handleFilterOpen}>
          <FilterListIcon />
        </IconButton> */}
        <IconButton onClick={handleAddOpen}>
          <AddIcon />
        </IconButton>
      </Stack>
      <SerialList
        serialList={serialList}
        onSerialSelect={handleSerialSelect}
        productLines={productLines}
        productFound={productFound}
        onLoadMore={handleLoadMore}
      />
      {/* <FilterDialog
        open={isFilterDialogOpen}
        onClose={() => setIsFilterDialogOpen(false)}
        onApply={handleFilterApply}
      /> */}
      {isAddDialogOpen && (
        <AddSerialDialog
          initialData={copiedSerialData}
          onClose={() => {
            setIsAddDialogOpen(false);
            setCopiedSerialData(null); // 다이얼로그가 닫힐 때 복사된 데이터 초기화
          }}
          onAdd={handleSerialAdd}
          lineList={productLines}
          productFound={productFound}
        />
      )}
      {selectedSerial && (
        <SerialDetailDialog
          open={!!selectedSerial}
          onClose={() => setSelectedSerial(null)}
          serialData={selectedSerial}
          onUpdate={handleSerialUpdate}
          productLines={productLines}
          productFound={productFound}
          onCopy={handleCopy}
        />
      )}
    </Box>
  );
};

export default EnhancedSerialManagerForMobile;
