// import React from "react";
// import { HashRouter, Routes, Route } from "react-router-dom";
// import Signin from "./pages/Signin/Signin";
// import SignOut from "./pages/SignOut/SignOut";
// import RouterAfterSignin from "./RouterAfterSignin";
// import PrivateRoute from "./components/PrivateRoute";
// import { AuthProvider } from "./contexts/AuthContext";
// import EventPage from "./pages/FoodQR/EventPage/EventPage";
// import FoodQR from "./pages/FoodQR/FoodQR";

// function Router() {
//   return (
//     <AuthProvider>
//       <HashRouter>
//         <Routes>
//           <Route path="/signin" element={<Signin />} />
//           <Route element={<PrivateRoute />}>
//             <Route path="/signout" element={<SignOut />} />
//             <Route path="/*" element={<RouterAfterSignin />} />
//           </Route>
//           <Route path="/food-qr" element={<FoodQR />} />
//           <Route path="/food-qr/event/19760342001242" element={<EventPage />} />
//         </Routes>
//       </HashRouter>
//     </AuthProvider>
//   );
// }

// export default Router;

import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import Signin from "./pages/Signin/Signin";
import SignOut from "./pages/SignOut/SignOut";
import RouterAfterSignin from "./RouterAfterSignin";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";
import EventPage from "./pages/FoodQR/EventPage/EventPage";
import FoodQR from "./pages/FoodQR/FoodQR";

function Router() {
  return (
    <AuthProvider>
      <HashRouter>
        <Routes>
          {/* 공개 접근 가능한 라우트 */}
          <Route path="/signin" element={<Signin />} />
          <Route path="/food-qr" element={<FoodQR />} />
          <Route path="/food-qr/event/:productId" element={<EventPage />} />

          {/* 인증이 필요한 라우트 */}
          <Route element={<PrivateRoute />}>
            <Route path="/signout" element={<SignOut />} />
            <Route path="/*" element={<RouterAfterSignin />} />
          </Route>
        </Routes>
      </HashRouter>
    </AuthProvider>
  );
}

export default Router;
