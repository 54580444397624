import axios from 'axios';
import { CONFIG } from '../../config';
import { client } from './client';

//로그인 비밀번호검증 회원가입
export const getSignin = async (data) => {
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/user/signin`,
      data: data,
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else {
      return [];
    }
  } catch (err) {
    return err;
  }
};

export const checkUserPwd = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/user/check`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};
export const getSignup = async (data) => {
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/user/signup`,
      data: data,
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};

//이용자정보 불러오기
export const getUserinfo = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/user/info`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.reponse.data;
  }
};
export const getTotalUser = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/user/list`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err.reponse.data;
  }
};

//내정보수정
export const modMyinfo = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/user/modify-user`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.reponse.data;
  }
};

//관리자의 이용자 정보 수정
export const modUserStatus = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/user/per`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};
export const modUserInfoByAdmin = async (data, queryParameter) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/user/modify-admin?phone=${queryParameter}`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.reponse.data;
  }
};

//회사정보
export const regCompanyInfo = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/company/info`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};
export const modCompanyInfo = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/company/mod?id=${data.id}`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};
export const getCompanyInfo = async (offset, limit) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/company/info?offset=${offset}&limit=${limit}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err.response.data.Message;
  }
};
export const getCompanyInfoWithFilter = async (key, value) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/company/info?${key}=${value}&status=True&offset=0&limit=1000`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
// 회사 담당자 매니저 정보
export const regManagerInfo = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/company/contacts/register`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const modManagerInfo = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/company/contacts/modify`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};

export const deleteManagerInfo = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/company/contacts/delete`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};

export const getManagerInfo = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/company/contacts/list?company_id=${data}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};

//회사 추가정보 명세와 내용
export const getCompanyEtcTitle = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/company/custom/title-list`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};
export const regCompanyEtcTitle = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/company/custom/title-create`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const modCompanyEtcTitle = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/company/custom/title-modify`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const getCompanyEtcContents = async (company_id) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/company/custom/value-list?company_id=${company_id}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};
export const regCompanyEtcContents = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/company/custom/value-create`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};

//프로덕트그룹
export const regProductGroup = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/product/group`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};
export const modProductGroup = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/product/group-mod?group_id=${data.id}`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};
export const getProductGroup = async (offset, limit) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/product/group?offset=${offset}&limit=${limit}`,
      headers: { Authorization: token },
    });

    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};

export const getProductGroupInfoWithFilter = async (key, value) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/product/group?status=True&${key}=${value}&offset=0&limit=1000`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
//창고정보
export const regWarehouse = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/warehouse/info`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};
export const modWarehouse = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/warehouse/info`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};
export const getWarehouse = async (offset, limit) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/warehouse/info?offset=${offset}&limit=${limit}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};
export const getWarehouseInfoWithFilter = async (key, value) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/warehouse/info?${key}=${value}&offset=0&limit=1000`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const regWarehouseProperty = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/warehouse/property`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};
export const getWarehouseProperty = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/warehouse/property`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};
export const regWarehouseType = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/warehouse/type`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};
export const getWarehouseType = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/warehouse/type`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};
//프로덕트(단품/세트)
export const regProductInfo = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/product/info`,
      data: JSON.stringify(data),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};

export const modProductInfo = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/product/mod`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getProductInfo = async (offset, limit) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/product/info?offset=${offset}&limit=${limit}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};

export const getSpecificProductInfo = async (param) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/product/info?offset=0&limit=1000&${param}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};

export const getProductInfoWithFilter = async (key, value) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/product/info?${key}=${value}&status=1&offset=0&limit=1000`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const getProductInfoWithFilterForAdmin = async (key, value) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/product/info?${key}=${value}&offset=0&limit=1000`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const getProductInfoWithQtt = async (queryParameter) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/stock-info?${queryParameter}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};

//product etc
export const getProductEtcTitle = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/product/etc/title-list`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};
export const regProductEtcTitle = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/product/etc/title-create`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const modProductEtcTitle = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/product/etc/title-modify`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const getProductEtcContents = async (product_id) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/product/etc/desc-list?product_id=${product_id}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};
export const regProductEtcContents = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/product/etc/desc-create`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};
export const modCustomPrice = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/stock/modify-custom-price`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};

//세트구성품 확인하기
export const getComponents = async (product_code) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/check-set?set_product_code=${product_code}&offset=0&limit=100`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err.response.data.Message;
  }
};
//세트 구성 요청하기
export const regManufacture = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/stock/generate-set`,
      data: JSON.stringify(data),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};
//세트 환원하기
export const rollbackManufacture = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/stock/serial/set-decompose`,
      data: JSON.stringify(data),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err;
  }
};
//창고별 재고 가져오기

export const getQuantity = async (product_code) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/list?product_code=${product_code}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response;
  }
};

//재고입출고
export const regMovement = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/stock/sheet`,
      data: JSON.stringify(data),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
//오입고 시리얼 환원, 오출고 시리얼 환입
export const rollbackSerialInbound = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/stock/serial/mistake`,
      data: JSON.stringify(data),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};

//입출고내역조회
export const getInventorySheetsList = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/sheet-list?date_start=${data.date_start}&date_end=${data.date_end}&company_name=${data.companyName}&type=${data.type.key}&user_name=${data.userName}&offset=${data.offset}&limit=${data.limit}&etc=${data.etc}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};

export const getInventorySheetsInfo = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/sheet-info?sheet_id=${
        data.sheet_id ? data.sheet_id : ''
      }&date_start=${data.date_start ? data.date_start : ''}&date_end=${
        data.date_end ? data.date_end : ''
      }&type=${data.type && data.type.key ? data.type.key : ''}&user_name=${
        data.userName ? data.userName : ''
      }&company_name=${data.companyName ? data.companyName : ''}&product_id=${
        data.productId ? data.productId : ''
      }&product_group_id=${
        data.productGroup && data.productGroup.code ? data.productGroup.id : ''
      }&warehouse_name=${data.warehouseName ? data.warehouseName : ''}&offset=${
        data.offset ? data.offset : '0'
      }&limit=${data.limit ? data.limit : '100000'}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};

//입출고전표 상세내용 조회 (모바일에서 클릭해서 열때 쓰자)
// export const getInventoryDetailedSheets = async (sheet_id) => {
//   const token = localStorage.getItem('token');
//   try {
//     const response = await client({
//       method: 'GET',
//       url: `${CONFIG.API_BASE_URL}/stock/sheet-detail?sheet_id=${sheet_id}`,
//       headers: { Authorization: token },
//     });
//     if (response.data !== undefined && response.data.code === undefined) {
//       return response;
//     } else return [];
//   } catch (err) {
//     return err.response.data.Message;
//   }
// };
//입출고 전표수정
export const ModInventorySheet = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/stock/sheet-modify`,
      data: JSON.stringify(data),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
//전표이력확인
export const getSheetsHistory = async (sheet_id) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/sheet-log-list?sheet_id=${sheet_id}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};

//전표비고불러오기 & 수정하기
export const getSheetEtc = async (sheet_id) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/sheet-etc?sheet_id=${sheet_id}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};
export const modSheetEtc = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/stock/sheet-etc-modify`,
      data: JSON.stringify(data),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};

export const getPartialQuantity = async (warehouse_code) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/quantity?warehouse_code=${warehouse_code}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};

export const getTotalQuantity = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/quantity`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};

export const getPrice = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/stock/price`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data.Message;
  }
};
//시리얼넘버 조회
export const getSerialsFromSheet = async (sheet_id, product_id) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/serial/code-list?sheet_id=${sheet_id}&product_id=${product_id}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err.response.data.Message;
  }
};

//특정 제품의 시리얼넘버 상태 전체조회
export const getSerialsStatus = async (product_id) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/total-serial-info?product_id=${product_id}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err.response.data.Message;
  }
};

//세트 구성품의 시리얼넘버 조회
export const getManufacturedSerialsFromSheet = async (sheet_id) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/serial/set-code-list?sheet_id=${sheet_id}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err.response.data.Message;
  }
};
//시리얼넘버 체크
export const checkSerial = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/serial/check?serial_code=${data.serial_number}&process_type=${data.type}&set_product_code=${data.set_product_code}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
//시리얼넘버 단순 존재유무체크
export const checkSerialExist = async (serial) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/serial/check-exist?code=${serial}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};

//시리얼넘버 이력조회
export const getSerialInfo = async (serial_number) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/serial-code-log?serial_code=${serial_number}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
//시리얼넘버 이력관리-타이틀(조회/작성/수정)
export const getSerialEtcTitle = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/serial/title-list`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const regSerialEtcTitle = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/stock/serial/title-create`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const modSerialEtcTitle = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/stock/serial/title-modify`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
//시리얼넘버 이력관리-컨텐츠(조회/작성/수정/삭제)
export const regSerialEtcContent = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/stock/serial/value-create`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const modSerialEtcContent = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/stock/serial/value-create`,
      data: data,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const deleteSerialEtcContent = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/stock/serial/value-delete?value_id=${data.value_id}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    console.log(err);
    return err;
  }
};

//!!!!!!!시리얼 고도화 프로젝트!!!!!!!!!!
//시리얼 고유성 체크 check-unique  (POST)
export const checkSerialUnique = async (serial) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(
      `${CONFIG.API_BASE_URL}/serials/check-unique/`,
      { serial_number: serial },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    console.error(err);
    // 여기서 에러 응답을 확인하고 적절한 객체를 반환합니다.
    if (err.response && err.response.status === 400) {
      return {
        success: false,
        status: err.response.status,
        data: err.response.data,
      };
    } else {
      // 다른 종류의 에러를 처리합니다.
      throw err;
    }
  }
};
//시리얼 optional한 추가관리항목명 가져오기
export const getSerialOptionalTitle = async () => {
  const token = localStorage.getItem('token');
  try {
    //header의 Authorization에 token
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/serials/global-additional-item-names/`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data;
  }
};
//시리얼 optional한 추가관리항목명 수정하기
export const modSerialOptionalTitle = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'PUT',
      url: `${CONFIG.API_BASE_URL}/serials/global-additional-item-names/`, // replace with your actual endpoint
      data: JSON.stringify({ data }),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data;
  }
};
//시리얼 관리품목라인 등록하기 'serial-managed-product-lines/'
export const regSerialManagedProductLine = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/serials/serial-managed-product-lines/`,
      data: JSON.stringify(data),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    }
  } catch (err) {
    return err.response.data;
  }
};
//순서 조회하기
export const getSerialColumnOrder = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/serials/column-orders/`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    }
  } catch (err) {
    return err.response.data;
  }
};
//순서 수정하기
export const modSerialColumnOrder = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'PUT',
      url: `${CONFIG.API_BASE_URL}/serials/column-orders/`,
      data: JSON.stringify(data),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    }
  } catch (err) {
    return err.response.data;
  }
};

//시리얼 관리품목라인 조회하기 'serial-managed-product-lines/'
export const getSerialManagedProductLine = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/serials/serial-managed-product-lines/`, // replace with your actual endpoint
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    }
  } catch (err) {
    return err.response.data;
  }
};
//시리얼 관리품목라인 수정하기 'serial-managed-product-lines/'
export const modSerialManagedProductLine = async (itemId, data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'PUT',
      url: `${CONFIG.API_BASE_URL}/serials/serial-managed-product-lines/${itemId}/`, // replace with your actual endpoint
      data: JSON.stringify(data),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    }
  } catch (err) {
    return err.response.data;
  }
};
//시리얼 관리품목라인 상태변경하기 'serial-managed-product-lines/'
export const modSerialManagedProductLineStatus = async (itemId, data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'PUT',
      url: `${CONFIG.API_BASE_URL}/serials/serial-managed-product-lines/${itemId}/`, // replace with your actual endpoint
      data: JSON.stringify(data),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    }
  } catch (err) {
    return err.response.data;
  }
};

//시리얼 관리품목 등록하기 'serial-managed-items/'
export const regSerialManagedItem = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/serials/serial-managed-items/`,
      data: JSON.stringify({ data }),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data;
  }
};
//시리얼 관리품목 조회하기'serial-managed-items/'
export const getSerialManagedItem = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/serials/serial-managed-items/`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data;
  }
};

export const ModSerialManagedItem = async (itemId, data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'PUT',
      url: `${CONFIG.API_BASE_URL}/serials/serial-managed-items/${itemId}/`,
      data: JSON.stringify({ data }),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data;
  }
};
export const getSerialItemInfo = async (data) => {
  const token = localStorage.getItem('token');
  try {
    // 비어있는 항목을 제거합니다
    const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});

    // 쿼리 문자열을 생성합니다
    const params = new URLSearchParams(filteredData).toString();

    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/serials/serial-item-info/?${params}`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data;
  }
};

export const regSerialItemInfo = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'POST',
      url: `${CONFIG.API_BASE_URL}/serials/serial-item-info/`,
      data: JSON.stringify({ data }),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data;
  }
};
export const modSerialItemInfo = async (itemId, data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'PUT',
      url: `${CONFIG.API_BASE_URL}/serials/serial-item-info/${itemId}/`,
      data: JSON.stringify({ data }),
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data;
  }
};
export const delSerialItemInfo = async (itemId) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'DELETE',
      url: `${CONFIG.API_BASE_URL}/serials/serial-item-info/${itemId}/`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data;
  }
};
export const getSerialHistory = async (itemId) => {
  const token = localStorage.getItem('token');
  try {
    const response = await client({
      method: 'GET',
      url: `${CONFIG.API_BASE_URL}/serials/serial-item-history/${itemId}/`,
      headers: { Authorization: token },
    });
    if (response.data !== undefined && response.data.code === undefined) {
      return response;
    } else return [];
  } catch (err) {
    return err.response.data;
  }
};
