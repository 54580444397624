import {
  Badge,
  Button,
  Collapse,
  Dialog,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getInventorySheetsInfo,
  getManufacturedSerialsFromSheet,
  getSerialsFromSheet,
  getSheetsHistory,
  rollbackManufacture,
  rollbackSerialInbound,
} from '../../apis/_axios/fetchData';
import { BlueButton, GrayButton } from '../StyledButton/StyledButton';
import { styled } from '@mui/system';
import swal from 'sweetalert';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { SerialsDialog } from './SerialsDialog';
import { formGray } from '../../Style/Colors/Colors';
import { classifyTypes } from '../../lib/ClassifyTypes/classifyTypes';
import { SelectDataTobePrint } from './SelectDataTobePrint';
import PrintIcon from '@mui/icons-material/Print';
import { StyledChekbox } from '../StyledCheckbox/StyledCheckbox';
import CircularBackground from '../InprogressExpression/CircularBackgound/CircularBackground';

export const InventoryReceipt = ({
  selectedSheet,
  handleDialogClose,
  user,
}) => {
  const [selectedSheetDetail, setSelectedSheetDetail] = useState([]);
  const StyledTableCell = styled(TableCell)({
    padding: 0,
  });
  const StyledSerial = styled(TableCell)({
    padding: 0,
    width: '75px',
  });
  const initSelectedSheetDetail = async () => {
    const result = await getInventorySheetsInfo({ sheet_id: selectedSheet.id });
    setSelectedSheetDetail(result.data.message);
  };
  const initSerialsTobeMod = async (products, globalSerials) => {
    for (let i = 0; i < selectedSheetDetail.length; i++) {
      const result = await getSerialsFromSheet(
        selectedSheet.id,
        selectedSheetDetail[i].product_id
      );
      products[i] = {
        ...selectedSheetDetail[i],
        serials: result.data.message,
      };
      for (let j = 0; j < result.data.message.length; j++) {
        globalSerials.push({
          product_code: selectedSheetDetail[i].product__product_code,
          serialNumber: result.data.message[j],
        });
      }
    }
  };
  useEffect(() => {
    initSelectedSheetDetail();
  }, []);
  const navigate = useNavigate();
  const modSheetInfo = async () => {
    let products = [];
    let serials = [];
    await initSerialsTobeMod(products, serials);
    // console.log({
    //   date: selectedSheet.date,
    //   sheet_id: selectedSheet.id,
    //   company_code: selectedSheet.company__code,
    //   company_id: selectedSheet.company_id,
    //   type: selectedSheet.type,
    //   company_name: selectedSheet.company__name,
    //   etc: selectedSheet.etc,
    //   product: products,
    //   global_serial_codes: serials,
    // });
    navigate('/modMovement', {
      state: {
        date: selectedSheet.date,
        sheet_id: selectedSheet.id,
        company_code: selectedSheet.company__code,
        company_id: selectedSheet.company_id,
        type: selectedSheet.type,
        company_name: selectedSheet.company__name,
        etc: selectedSheet.etc,
        product: products,
        global_serial_codes: serials,
      },
    });
  };

  //생산품 환원
  const [serialTobeRollback, setSerialTobeRollback] = useState([]);
  const [isRollbackManufactureDlgOpened, setIsRollbackManufactureDlgOpened] =
    useState(false);

  const handleRollbackDlgOpen = async () => {
    await getManufacturedSerialsFromSheet(selectedSheet.id).then((res) =>
      setSerialsFromSingleProduct(res.data.message)
    );
    setIsRollbackManufactureDlgOpened(true);
  };
  const handleRollbackDlgClose = () => {
    setIsRollbackManufactureDlgOpened(false);
  };
  const makeRollbackList = (serial) => {
    let result = [...serialTobeRollback];
    if (result.find((item) => item === serial)) {
      let filtered_result = result.filter((item) => item !== serial);
      setSerialTobeRollback(filtered_result);
    } else {
      result.push(serial);
      setSerialTobeRollback(result);
    }
  };
  const goRollbackManufacture = async () => {
    const response = await rollbackManufacture({
      serials: serialTobeRollback,
    });
    if (response.status === 200) {
      swal('성공', '환원처리 완료하였습니다', 'success').then((value) => {
        window.location.reload();
      });
    } else if (
      response.data &&
      response.data.failed_serial &&
      response.data.failed_serial[0].length > 0
    ) {
      swal(
        '실패',
        `${response.data.failed_serial[0]} 시리얼은 이미 출고/사용되어 환원처리가 불가능합니다.`,
        'error'
      );
    } else {
      swal('실패', '등록실패. 잠시후 다시 시도해주세요 ', 'error');
    }
  };
  const goRollbackInbound = async () => {
    const response = await rollbackSerialInbound({
      serials: serialTobeRollback,
      sheet_id: selectedSheet.id,
    });
    if (response.status === 200) {
      swal('성공', '과입고 수정을 완료하였습니다.', 'success').then((value) => {
        window.location.reload();
      });
    } else if (response.data.message === '환원에 실패했습니다.') {
      let data = [];
      response.data.failed_serial_code.map((item) =>
        data.push(item.serial_code)
      );
      swal(
        '실패',
        `이미 처리된 시리얼이 포함되어 환원할 수 없습니다.(처리된 시리얼 : ${data})`,
        'error'
      );
    }
  };
  const goRollbackOutbound = async () => {
    const response = await rollbackSerialInbound({
      serials: serialTobeRollback,
      sheet_id: selectedSheet.id,
    });
    if (response.status === 200) {
      swal('성공', '시리얼 출고내역을 삭제하였습니다.', 'success').then(
        (value) => {
          window.location.reload();
        }
      );
    }
  };

  const confirmRollback = async () => {
    if (selectedSheet.type === 'generate') {
      swal({
        title: '생산품 환원',
        text: '선택한 생산품의 구성품을 환원하고 발급된 시리얼 번호를 삭제합니다.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willCheck) => {
        if (willCheck) {
          goRollbackManufacture();
        }
      });
    } else if (selectedSheet.type === 'inbound') {
      swal({
        title: '과입고 시리얼 제거',
        text: '선택된 시리얼을 삭제하고 과입고된 재고수량을 차감합니다.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willCheck) => {
        if (willCheck) {
          goRollbackInbound();
        }
      });
    } else if (selectedSheet.type === 'outbound') {
      swal({
        title: '출고 시리얼 환입',
        text: '선택된 시리얼의 출고내역을 삭제하고 재고수량을 증가시킵니다.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willCheck) => {
        if (willCheck) {
          goRollbackOutbound();
        }
      });
    }
    // if (selectedSheet.type === 'generate') {
    //   if (
    //     window.confirm(
    //       '선택한 생산품의 구성품을 환원하고 발급된 시리얼 번호를 삭제합니다.'
    //     )
    //   ) {
    //     const response = await rollbackManufacture({
    //       serials: serialTobeRollback,
    //     });
    //     if (response.status === 200) {
    //       swal('실패','환원처리 완료하였습니다','error');
    //       handleDialogClose();
    //     } else {
    //       swal('실패','등록실패. 잠시후 다시 시도해주세요 ','error');
    //     }
    //   }
    // } else if (selectedSheet.type === 'inbound') {
    //   if (
    //     window.confirm(
    //       '선택한 시리얼을 삭제하고 과입고된 재고수량을 차감합니다.'
    //     )
    //   ) {
    //     const response = await rollbackSerialInbound({
    //       serials: serialTobeRollback,
    //       sheet_id: selectedSheet.id,
    //     });
    //     if (response.status === 200) {
    //       swal('실패','과입고 수정을 완료하였습니다.','error');

    //       handleDialogClose();
    //     } else {
    //       swal('실패','과입고 수정을 완료하였습니다.','error');
    //     }
    //   }
    // }
  };

  const [serialsFromSingleProduct, setSerialsFromSingleProduct] = useState();
  const [isSerialsDlgOpened, setIsSerialsDlgOpened] = useState(false);
  const handleSerialsDialogOpen = async (item) => {
    if (item.sheet__type === 'generate') {
      await getManufacturedSerialsFromSheet(item.sheet_id).then((res) =>
        setSerialsFromSingleProduct(res.data.message)
      );
    } else {
      await getSerialsFromSheet(item.sheet_id, item.product_id).then((res) =>
        setSerialsFromSingleProduct(res.data.message)
      );
    }
    // await getSerialsFromSheet(selectedSheet.id, item.product_id).then((res) =>
    //   setSerialsFromSingleProduct(res.data.message)
    setIsSerialsDlgOpened(true);
  };
  const handleSerialsDialogClose = () => {
    setSerialsFromSingleProduct();
    setIsSerialsDlgOpened(false);
  };
  //로그조회
  const [sheetHistory, setSheetHistory] = useState([]);
  const [isHistoryExpanded, setIsHistoryExpanded] = useState(false);
  useEffect(() => {
    getSheetsHistory(selectedSheet.id).then((res) =>
      setSheetHistory(res.data.message)
    );
  }, [selectedSheet]);
  const handleHistoryInfo = () => {
    setIsHistoryExpanded(!isHistoryExpanded);
  };
  //라벨 렌더링시 화면 스피너 컨트롤
  const [isLabelRendering, setIsLabelRendering] = useState(false);
  const startLabelRendering = () => {
    setIsLabelRendering(true);
  };
  const completeLabelRendering = () => {
    setIsLabelRendering(false);
  };
  //프린팅
  const [didCheckBoxClicked, setDidCheckBoxClicked] = useState(false);
  const [serialNumber, setSerialNumber] = useState([]);
  const [dataTobePrint, setDataTobePrint] = useState([]);
  const [labelType, setLabelType] = useState();
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const openCheckBox = () => {
    setDidCheckBoxClicked(!didCheckBoxClicked);
  };
  const data = [];
  const handleDrawerOpen = () => {
    setIsDrawerOpened(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerOpened(false);
  };

  const printData = () => {
    if (dataTobePrint.length === 0) {
      swal('실패', '인쇄할 항목을 선택해주세요', 'error');
    } else {
      if (selectedSheet.type !== 'inbound') {
        swal('라벨사이즈', '출력하실 라벨의 사이즈를 선택해주세요', {
          buttons: {
            smallType: {
              text: '작은라벨',
              value: 'small',
            },
            bigType: {
              text: '큰라벨',
              value: 'big',
            },
          },
        }).then((value) => {
          switch (value) {
            case 'small':
              setLabelType('small');
              setIsLabelRendering(true);
              setTimeout(() => handleDrawerOpen(), 100);
              break;
            case 'big':
              setLabelType('big');
              setIsLabelRendering(true);
              setTimeout(() => handleDrawerOpen(), 100);
              break;
            default:
              break;
          }
        });
      } else {
        setLabelType('small');
        setIsLabelRendering(true);
        setTimeout(() => handleDrawerOpen(), 100);
      }
    }
  };
  //재귀 관련전표 불러오기
  const [isRelatedDlgOpened, setIsRelatedDlgOpened] = useState(false);
  const handleRelatedDlgOpen = () => {
    setIsRelatedDlgOpened(true);
  };
  const handleRelatedDlgClose = () => {
    setIsRelatedDlgOpened(false);
  };
  const [relatedSheet, setRelatedSheet] = useState();

  const openRelatedSheet = async () => {
    const relatedResult = await getInventorySheetsInfo({
      sheet_id: selectedSheet.related_sheet_id,
    });
    setRelatedSheet({
      id: relatedResult.data.message[0].sheet_id,
      company__name: relatedResult.data.message[0].sheet__company__name,
      company__code: relatedResult.data.message[0].sheet__company__code,
      company_id: relatedResult.data.message[0].sheet__company_id,
      created_at: relatedResult.data.message[0].sheet__created_at,
      date: relatedResult.data.message[0].sheet__date,
      document_num: relatedResult.data.message[0].sheet__document_num,
      etc: relatedResult.data.message[0].sheet__etc,
      related_sheet_id: relatedResult.data.message[0].sheet__realted_sheet_id,
      type: relatedResult.data.message[0].sheet__type,
      user__name: relatedResult.data.message[0].sheet__user__name,
    });
    handleRelatedDlgOpen();
  };

  return (
    <Stack padding='16px' sx={{ position: 'relative' }}>
      {isLabelRendering && (
        <CircularBackground
          message={`${dataTobePrint.length}건의 데이터를 불러오고 있습니다`}
        />
      )}

      {isRollbackManufactureDlgOpened && (
        <Dialog
          onClose={handleRollbackDlgClose}
          open={isRollbackManufactureDlgOpened}
        >
          {serialsFromSingleProduct.length > 0 ? (
            <Stack padding='16px'>
              <h3>환원할 생산품의 시리얼 번호를 선택해주세요.</h3>
              <Stack m='16px 0' spacing={1}>
                {serialsFromSingleProduct &&
                  serialsFromSingleProduct.map((item) => (
                    <Stack
                      key={item.generate_serial_code}
                      direction={'row'}
                      justifyContent='space-around'
                    >
                      <span>{item.generate_serial_code}</span>
                      <StyledChekbox
                        id={item.generate_serial_code}
                        type='checkbox'
                        onClick={(e) => makeRollbackList(e.target.id)}
                      ></StyledChekbox>
                    </Stack>
                  ))}
              </Stack>

              <Stack
                direction={'row'}
                spacing={2}
                sx={{ width: '100%', bottom: '16px' }}
              >
                <GrayButton onClick={handleRollbackDlgClose}>취소</GrayButton>
                <BlueButton onClick={confirmRollback}>확인</BlueButton>
              </Stack>
            </Stack>
          ) : (
            <Stack
              alignItems={'center'}
              justifyContent='center'
              width='600px'
              height='400px'
              position='relative'
            >
              <span>환원할 시리얼번호가 존재하지 않습니다.</span>
              <GrayButton
                onClick={handleRollbackDlgClose}
                sx={{ position: 'absolute', bottom: '16px', width: '90%' }}
              >
                닫기
              </GrayButton>
            </Stack>
          )}
        </Dialog>
      )}
      {isRelatedDlgOpened && (
        <Dialog
          onClose={handleRelatedDlgClose}
          open={isRelatedDlgOpened}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '50%',
              },
            },
          }}
        >
          <InventoryReceipt
            user={user && user}
            selectedSheet={relatedSheet}
            handleDialogClose={handleRelatedDlgClose}
          />
        </Dialog>
      )}
      <Badge
        color='secondary'
        badgeContent={'X'}
        onClick={handleDialogClose}
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          right: '16px',
          top: '16px',
          '& .MuiBadge-badge': {
            color: 'yellow',
            backgroundColor: '#686868',
          },
        }}
      />
      <Stack direction='column' justifyContent='center'>
        <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
          <span style={{ fontSize: '1.5rem' }}>
            {classifyTypes(selectedSheet.type)}내역
          </span>
        </div>
        {serialsFromSingleProduct && (
          <Dialog onClose={handleSerialsDialogClose} open={isSerialsDlgOpened}>
            <SerialsDialog
              type={selectedSheet.type}
              serials={serialsFromSingleProduct}
              handleSerialsDialogClose={handleSerialsDialogClose}
            />
          </Dialog>
        )}
        <TableContainer style={{ paddingBottom: '50px' }}>
          <Table size='small' sx={{ border: 1 }}>
            <TableBody>
              {selectedSheet && selectedSheet.created_at && (
                <TableRow>
                  <TableCell
                    width='200px'
                    align='center'
                    sx={{ border: 1, backgroundColor: `${formGray}` }}
                  >
                    문서번호
                  </TableCell>
                  <TableCell align='left' sx={{ border: 1 }}>
                    {selectedSheet.created_at.substr(0, 4)}-
                    {classifyTypes(selectedSheet.type)}-
                    {String(selectedSheet.id).padStart(5, '0')}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell
                  width='200px'
                  align='center'
                  sx={{ border: 1, backgroundColor: `${formGray}` }}
                >
                  처리일
                </TableCell>
                <TableCell align='left' sx={{ border: 1 }}>
                  {selectedSheet.date}
                </TableCell>
              </TableRow>
              {selectedSheet.updated_at && (
                <TableRow>
                  <TableCell
                    width='200px'
                    align='center'
                    sx={{ border: 1, backgroundColor: `${formGray}` }}
                  >
                    작성일시
                  </TableCell>
                  <TableCell align='left' sx={{ border: 1 }}>
                    {selectedSheet.updated_at.substr(0, 10)} /{' '}
                    {selectedSheet.updated_at.substr(11, 5)}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell
                  width='200px'
                  align='center'
                  sx={{ border: 1, backgroundColor: `${formGray}` }}
                >
                  담당자
                </TableCell>
                <TableCell sx={{ border: 1 }}>
                  {selectedSheet.user__name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width='200px'
                  align='center'
                  sx={{ border: 1, backgroundColor: `${formGray}` }}
                >
                  거래처
                </TableCell>
                <TableCell sx={{ border: 1 }}>
                  {selectedSheet.company__name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width='200px'
                  align='center'
                  sx={{ border: 1, backgroundColor: `${formGray}` }}
                >
                  거래유형
                </TableCell>
                <TableCell sx={{ border: 1 }}>
                  <Stack direction='row' alignItems={'center'} spacing={3}>
                    <span>{classifyTypes(selectedSheet.type)}</span>
                    {selectedSheet.type === 'used' && (
                      <Button
                        variant='outlined'
                        sx={{ padding: '2px 4px' }}
                        onClick={openRelatedSheet}
                      >
                        생산전표확인
                      </Button>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer size='small' style={{ paddingBottom: '30px' }}>
          <Table>
            <TableHead sx={{ backgroundColor: `${formGray}` }}>
              <TableRow>
                <StyledTableCell sx={{ border: 1 }} align='center'>
                  제품코드
                </StyledTableCell>
                <StyledTableCell align='center' sx={{ border: 1 }}>
                  품목그룹
                </StyledTableCell>
                <StyledTableCell align='center' sx={{ border: 1 }}>
                  제품명
                </StyledTableCell>
                <StyledTableCell align='center' sx={{ border: 1 }}>
                  수량
                </StyledTableCell>
                {user && user.admin && selectedSheet.type === 'inbound' && (
                  <StyledTableCell align='center' sx={{ border: 1 }}>
                    단가
                  </StyledTableCell>
                )}
                {user && user.admin && selectedSheet.type === 'generate' && (
                  <StyledTableCell align='center' sx={{ border: 1 }}>
                    단가
                  </StyledTableCell>
                )}
                <StyledTableCell align='center' sx={{ border: 1 }}>
                  적재위치
                </StyledTableCell>
                <StyledTableCell align='center' sx={{ border: 1 }}>
                  비고
                </StyledTableCell>
                <StyledSerial align='center' sx={{ border: 1 }}>
                  시리얼
                </StyledSerial>
                {didCheckBoxClicked ? (
                  <StyledTableCell align='center' sx={{ border: 1 }}>
                    인쇄
                  </StyledTableCell>
                ) : (
                  ''
                )}
              </TableRow>
            </TableHead>
            {selectedSheetDetail && selectedSheetDetail.length > 0 ? (
              selectedSheetDetail.map((item, idx) => {
                return (
                  <TableBody key={idx}>
                    <TableRow>
                      <TableCell align='center' sx={{ border: 1 }}>
                        {item.product__product_code}
                      </TableCell>
                      <TableCell align='center' sx={{ border: 1 }}>
                        {item.product__product_group__name}
                      </TableCell>
                      <TableCell align='center' sx={{ border: 1 }}>
                        {item.product__name}
                      </TableCell>
                      <TableCell align='center' sx={{ border: 1 }}>
                        {item.quantity}
                      </TableCell>
                      {user && user.admin && item.sheet__type === 'inbound' && (
                        <TableCell align='center' sx={{ border: 1 }}>
                          {item.unit_price}
                        </TableCell>
                      )}
                      {user &&
                        user.admin &&
                        item.sheet__type === 'generate' && (
                          <TableCell align='center' sx={{ border: 1 }}>
                            {item.unit_price}
                          </TableCell>
                        )}
                      <TableCell align='center' sx={{ border: 1 }}>
                        {item.location}
                      </TableCell>
                      <TableCell align='center' sx={{ border: 1 }}>
                        {item.etc}
                      </TableCell>
                      <TableCell align='center' sx={{ border: 1 }}>
                        {item.product__is_serial && (
                          <VisibilityIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleSerialsDialogOpen(item)}
                          />
                        )}
                      </TableCell>
                      {didCheckBoxClicked && (
                        <SelectDataTobePrint
                          item={item}
                          setDataTobePrint={setDataTobePrint}
                          dataTobePrint={dataTobePrint}
                          data={data}
                          selectedSheetDetail={selectedSheetDetail}
                          selectedSheet={selectedSheet}
                          setSerialNumber={setSerialNumber}
                          serialNumber={serialNumber}
                          idx={idx}
                        />
                      )}
                    </TableRow>
                  </TableBody>
                );
              })
            ) : (
              <TableBody>
                <TableRow align='center'>
                  <td colSpan='8'>삭제된 전표입니다</td>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TableContainer style={{ paddingBottom: '30px' }}>
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCell
                  width='100px'
                  sx={{ border: 1, backgroundColor: `${formGray}` }}
                  align='center'
                >
                  특이사항
                </StyledTableCell>
                <StyledTableCell sx={{ border: 1, paddingLeft: '8px' }}>
                  {selectedSheet.etc}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Collapse
          in={isHistoryExpanded}
          timeout='auto'
          unmountOnExit
          sx={{ margin: '16px 0' }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: `${formGray}` }}>
              <TableRow>
                <StyledTableCell sx={{ border: 1 }} align='center' colSpan='10'>
                  작성이력
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ border: 1 }} align='center'>
                  제품코드
                </StyledTableCell>
                <StyledTableCell align='center' sx={{ border: 1 }}>
                  품목그룹
                </StyledTableCell>
                <StyledTableCell align='center' sx={{ border: 1 }}>
                  제품명
                </StyledTableCell>
                <StyledTableCell align='center' sx={{ border: 1 }}>
                  수량
                </StyledTableCell>
                {user && user.admin && selectedSheet.type === 'inbound' && (
                  <StyledTableCell align='center' sx={{ border: 1 }}>
                    단가
                  </StyledTableCell>
                )}
                {user && user.admin && selectedSheet.type === 'generate' && (
                  <StyledTableCell align='center' sx={{ border: 1 }}>
                    단가
                  </StyledTableCell>
                )}
                <StyledTableCell align='center' sx={{ border: 1 }}>
                  적재위치
                </StyledTableCell>
                <StyledTableCell align='center' sx={{ border: 1 }}>
                  비고
                </StyledTableCell>
                <StyledSerial align='center' sx={{ border: 1 }}>
                  작성자
                </StyledSerial>
                <StyledSerial align='center' sx={{ border: 1 }}>
                  처리일
                </StyledSerial>
                <StyledSerial align='center' sx={{ border: 1 }}>
                  작성일시
                </StyledSerial>
              </TableRow>
            </TableHead>
            <TableBody>
              {sheetHistory.map((item) => {
                return (
                  <TableRow key={item.id} align='center' sx={{ border: 1 }}>
                    <StyledTableCell align='center' sx={{ border: 1 }}>
                      {item.product__product_code}
                    </StyledTableCell>
                    <StyledTableCell align='center' sx={{ border: 1 }}>
                      {item.product__product_group__name}
                    </StyledTableCell>
                    <StyledTableCell align='center' sx={{ border: 1 }}>
                      {item.product__name}
                    </StyledTableCell>
                    <StyledTableCell align='center' sx={{ border: 1 }}>
                      {item.quantity}
                    </StyledTableCell>
                    {user &&
                      user.admin &&
                      item.sheet_log__type === 'inbound' && (
                        <StyledTableCell align='center' sx={{ border: 1 }}>
                          {item.unit_price}
                        </StyledTableCell>
                      )}
                    {user &&
                      user.admin &&
                      item.sheet_log__type === 'generate' && (
                        <StyledTableCell align='center' sx={{ border: 1 }}>
                          {item.unit_price}
                        </StyledTableCell>
                      )}
                    <StyledTableCell align='center' sx={{ border: 1 }}>
                      {item.location}
                    </StyledTableCell>
                    <StyledTableCell align='center' sx={{ border: 1 }}>
                      {item.etc}
                    </StyledTableCell>
                    <StyledTableCell align='center' sx={{ border: 1 }}>
                      {item.sheet_log__user_name}
                    </StyledTableCell>
                    <StyledTableCell align='center' sx={{ border: 1 }}>
                      {item.sheet_log__date}
                    </StyledTableCell>
                    <StyledTableCell
                      align='center'
                      sx={{ border: 1, width: '150px' }}
                    >
                      <span>{item.sheet_log__timestamp.slice(0, 10)}</span>
                      <span>/{item.sheet_log__timestamp.slice(11, 16)}</span>
                    </StyledTableCell>
                    <StyledTableCell align='center' sx={{ border: 1 }}>
                      {item.product__is_serial && (
                        <VisibilityIcon
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleSerialsDialogOpen(item)}
                        />
                      )}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Collapse>
        {sheetHistory.length > 0 ? (
          <Stack direction={'row'} width='100%'>
            <Stack direction={'row'} spacing={2} width='100%'>
              <Button
                variant='outlined'
                sx={{ width: '20%' }}
                onClick={handleHistoryInfo}
              >
                <span>수정이력</span>
              </Button>
              {selectedSheet.type === 'generate' ? (
                <GrayButton
                  onClick={handleRollbackDlgOpen}
                  sx={{ width: '30%' }}
                >
                  생산품 환원
                </GrayButton>
              ) : selectedSheet.type === 'inbound' ? (
                <Stack direction={'row'} spacing={2}>
                  {selectedSheetDetail &&
                    selectedSheetDetail.length > 0 &&
                    !selectedSheet.fromSerialManageMenu && (
                      <BlueButton onClick={modSheetInfo}>수정</BlueButton>
                    )}
                  <GrayButton
                    onClick={handleRollbackDlgOpen}
                    sx={{ fontSize: '13px', width: '200px' }}
                  >
                    과입고 시리얼 환원
                  </GrayButton>
                </Stack>
              ) : selectedSheet.type === 'outbound' &&
                selectedSheetDetail &&
                selectedSheetDetail.length > 0 ? (
                <Stack direction={'row'} spacing={2}>
                  {selectedSheetDetail &&
                    selectedSheetDetail.length > 0 &&
                    !selectedSheet.fromSerialManageMenu && (
                      <BlueButton onClick={modSheetInfo}>수정</BlueButton>
                    )}
                  <GrayButton
                    onClick={handleRollbackDlgOpen}
                    sx={{ fontSize: '10px' }}
                  >
                    출고 시리얼 환입
                  </GrayButton>
                </Stack>
              ) : (
                <>
                  {!selectedSheet.fromSerialManageMenu ? (
                    <BlueButton onClick={modSheetInfo} sx={{ width: '20%' }}>
                      수정
                    </BlueButton>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Stack>
            {selectedSheet.type === 'inbound' && !didCheckBoxClicked && (
              <Button sx={{ width: '15%' }}>
                <PrintIcon onClick={openCheckBox} />
              </Button>
            )}
            {selectedSheet.type === 'generate' && !didCheckBoxClicked && (
              <Button sx={{ width: '15%' }}>
                <PrintIcon onClick={openCheckBox} />
              </Button>
            )}
            {didCheckBoxClicked && !isLabelRendering && (
              <BlueButton onClick={printData} sx={{ width: '15%' }}>
                인쇄
              </BlueButton>
            )}
          </Stack>
        ) : (
          <Stack direction={'row'} width='100%'>
            <Stack direction={'row'} spacing={2} width='100%'>
              {selectedSheet.type === 'generate' ? (
                <GrayButton
                  onClick={handleRollbackDlgOpen}
                  sx={{ width: '30%' }}
                >
                  생산품 환원
                </GrayButton>
              ) : selectedSheet.type === 'inbound' &&
                !selectedSheet.justCreated === true ? (
                <Stack direction={'row'} spacing={2}>
                  {selectedSheetDetail &&
                    selectedSheetDetail.length > 0 &&
                    !selectedSheet.fromSerialManageMenu && (
                      <BlueButton onClick={modSheetInfo}>수정</BlueButton>
                    )}
                  <GrayButton
                    onClick={handleRollbackDlgOpen}
                    sx={{ fontSize: '13px', width: '200px' }}
                  >
                    과입고 시리얼 환원
                  </GrayButton>
                </Stack>
              ) : selectedSheet.type === 'outbound' &&
                selectedSheetDetail &&
                selectedSheetDetail.length > 0 &&
                !selectedSheet.fromSerialManageMenu ? (
                <Stack direction={'row'} spacing={2}>
                  <BlueButton onClick={modSheetInfo} sx={{ width: '20%' }}>
                    수정
                  </BlueButton>
                  <GrayButton
                    onClick={handleRollbackDlgOpen}
                    sx={{ fontSize: '11px' }}
                  >
                    출고 시리얼 환입
                  </GrayButton>
                </Stack>
              ) : (
                ''
              )}
            </Stack>
            {selectedSheet.type === 'inbound' && !didCheckBoxClicked && (
              <Button sx={{ width: '15%' }}>
                <PrintIcon onClick={openCheckBox} />
              </Button>
            )}
            {selectedSheet.type === 'generate' && !didCheckBoxClicked && (
              <Button sx={{ width: '15%' }}>
                <PrintIcon onClick={openCheckBox} />
              </Button>
            )}
            {didCheckBoxClicked && !isLabelRendering && (
              <BlueButton onClick={printData} sx={{ width: '15%' }}>
                인쇄
              </BlueButton>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
