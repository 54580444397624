import { FormControl, Input, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  getSerialEtcTitle,
  modSerialEtcTitle,
  regSerialEtcTitle,
} from '../../../../apis/_axios/fetchData';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {
  BlueButton,
  GrayButton,
} from '../../../../components/StyledButton/StyledButton';
import { StyledChekbox } from '../../../../components/StyledCheckbox/StyledCheckbox';

export const SetEtcTitleTab = ({
  handleSerialMngDialogClose,
  handleSnackbarContents,
  handleSnackbarOpen,
}) => {
  const { register, handleSubmit } = useForm({
    criteriaMode: 'all',
  });
  //신규타이틀 등록
  const [additionalTitle, setAdditionalTitle] = useState();
  const submitAdditionalTitle = async () => {
    const response = await regSerialEtcTitle(additionalTitle);
    if (response.status === 200) {
      handleSnackbarOpen();
      handleSnackbarContents('추가정보메뉴 등록 성공', 'success');
    } else {
      handleSnackbarOpen();
      handleSnackbarContents(
        '추가정보메뉴 등록 실패. 잠시후 다시 시도해주세요.',
        'error'
      );
    }
  };
  //기존타이틀 불러오기
  const [serialEtcTitles, setSerialEtcTitles] = useState();
  useEffect(() => {
    getSerialEtcTitle().then((res) => setSerialEtcTitles(res.data.message));
  }, []);
  //기존타이틀 수정
  const onSubmit = async (data) => {
    for (let i = 0; i < data.data.length; i++) {
      const response = await modSerialEtcTitle(data.data[i]);
      if (response.status === 200) {
        handleSnackbarOpen();
        handleSnackbarContents(
          '시리얼이력 메뉴수정에 성공하였습니다.',
          'success'
        );
        handleSerialMngDialogClose();
      } else {
        handleSnackbarOpen();
        handleSnackbarContents(
          '실패하였습니다. 잠시후 다시 시도해주세요.',
          'error'
        );
      }
    }
  };
  //엔터막기
  const preventEnterKeyDown = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
    }
  };
  return (
    <Stack sx={{ minWidth: '350px', minHeight: '600px', position: 'relative' }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => preventEnterKeyDown(e)}
      >
        <Stack
          direction={'row'}
          alignItems='center'
          justifyContent={'space-between'}
          marginBottom='16px'
        >
          {serialEtcTitles && serialEtcTitles.length < 11 && (
            <>
              <TextField
                label='이력관리 항목추가(최대 10개)'
                size='small'
                sx={{ width: '80%' }}
                onChange={(e) => setAdditionalTitle({ title: e.target.value })}
              />
              <AddBoxIcon
                sx={{ color: '#3271ff', fontSize: '2rem', cursor: 'pointer' }}
                onClick={submitAdditionalTitle}
              />
            </>
          )}
        </Stack>
        <Stack sx={{ maxHeight: '500px', overflow: 'auto' }}>
          {serialEtcTitles &&
            serialEtcTitles.map((item) => (
              <Stack
                key={item.id}
                direction={'row'}
                alignItems='center'
                spacing={1}
                margin='4px 0'
              >
                <Input
                  type='text'
                  sx={{ display: 'none' }}
                  value={item.id}
                  {...register(`data[${item.id - 1}].title_id`)}
                />
                {item.status ? (
                  <StyledChekbox
                    type='checkbox'
                    {...register(`data[${item.id - 1}].status`)}
                    defaultChecked
                  ></StyledChekbox>
                ) : (
                  <StyledChekbox
                    type='checkbox'
                    {...register(`data[${item.id - 1}].status`)}
                  ></StyledChekbox>
                )}
                <FormControl sx={{ width: '100%' }}>
                  <input
                    placeholder='항목명을 입력해주세요'
                    type='text'
                    defaultValue={item.title}
                    {...register(`data[${item.id - 1}].title`)}
                    style={{
                      padding: '8px',
                      borderRadius: '4px',
                      border: '2px solid #e0e0e0',
                    }}
                  />
                </FormControl>
              </Stack>
            ))}
        </Stack>
        <Stack
          direction={'row'}
          spacing={2}
          sx={{ position: 'absolute', width: '100%', bottom: '16px' }}
        >
          <GrayButton onClick={handleSerialMngDialogClose}>취소</GrayButton>
          <BlueButton type='submit'>설정</BlueButton>
        </Stack>
      </form>
    </Stack>
  );
};
