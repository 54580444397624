import React, { useState, useEffect } from 'react';
import { TableCell } from '@mui/material';
import {
  getComponents,
  getManufacturedSerialsFromSheet,
  getSerialsFromSheet,
} from '../../apis/_axios/fetchData';

export const SelectDataTobePrint = ({
  item,
  setDataTobePrint,
  data,
  selectedSheetDetail,
  selectedSheet,
  setSerialNumber,
  serialNumber,
}) => {
  const [componentsInfo, setComponentsInfo] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const serialFromInbound = async () => {
    await getSerialsFromSheet(
      selectedItem.sheet_id,
      selectedItem.product_id
    ).then((res) => {
      setSerialNumber(res.data.message);
    });
  };
  const distributeProductInfo = async () => {
    for (let i = 0; i < item.quantity; i++) {
      if (selectedSheet.type === 'inbound') {
        await data.push({
          product__product_code: selectedItem.product__product_code,
          product__product_group__name:
            selectedItem.product__product_group__name,
          product__name: selectedItem.product__name,
          quantity: selectedItem.quantity,
          unit_price: selectedItem.unit_price,
          location: selectedItem.location,
          product__is_serial:
            serialNumber[i] && serialNumber[i].length > 0 && serialNumber[i],
        });
      }
    }
    setDataTobePrint(data);
  };
  useEffect(() => {
    selectedItem && serialFromInbound();
  }, [selectedItem]);
  useEffect(() => {
    selectedItem && distributeProductInfo();
    setSelectedItem(null);
  }, [serialNumber]);

  useEffect(() => {
    if (selectedSheet.type === 'generate') {
      getManufacturedSerialsFromSheet(selectedSheet.id).then((res) => {
        setSerialNumber(res.data.message);
      });
      let components = [];
      getComponents(selectedSheetDetail[0].product__product_code).then((res) =>
        res.data.message.map((item) => components.push(item))
      );
      setComponentsInfo(components);
    }
  }, []);
  return (
    <>
      <TableCell sx={{ border: 1 }}>
        <input
          key={item.id}
          type='radio'
          name='check'
          onClick={() => {
            if (selectedSheet.type === 'inbound') {
              setSelectedItem(item);
            } else if (selectedSheet.type === 'generate') {
              for (let i = 0; i < item.quantity; i++) {
                data.push({
                  product__product_code: item.product__product_code,
                  product__product_group__name:
                    item.product__product_group__name,
                  product__name: item.product__name,
                  quantity: item.quantity,
                  unit_price: item.unit_price,
                  location: item.location,
                  product__is_serial:
                    item.product__is_serial && serialNumber[i],
                  components: componentsInfo,
                });
              }
              setDataTobePrint(data);
            }
          }}
        />
      </TableCell>
    </>
  );
};
