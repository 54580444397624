import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Avatar,
  Paper,
  Button,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import PeopleIcon from '@mui/icons-material/People';
import { useNavigate } from 'react-router-dom';
import TotalUserInfo from './TotalUserInfo/TotalUserInfo';
import ManageTotalUser from './ManageTotalUser/ManageTotalUser';
import CustomizedSnackbars from '../../components/CustomizedSnackbars/CustomizedSnackbars';
import {
  getTotalUser,
  checkUserPwd,
  modUserStatus,
} from '../../apis/_axios/fetchData';
import swal from 'sweetalert';

const ProfileCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  background: 'linear-gradient(to bottom, #1976d2, #1565c0)',
  color: 'white',
}));

const InfoCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const Mypage = ({ handleTitle, user }) => {
  const navigate = useNavigate();
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [password, setPassword] = useState('');
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState('info');

  useEffect(() => {
    if (user) {
      handleTitle({ titleName: '마이페이지', user: user });
    }
  }, [user, handleTitle]);

  useEffect(() => {
    getTotalUser().then((res) => setUsers(res.data.user_list));
  }, [snackbarOpen]);

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const handleSnackbarContents = (msg, severity) => {
    setMsg(msg);
    setSeverity(severity);
  };

  const handleEditProfile = () => {
    setOpenPasswordDialog(true);
  };

  const handlePasswordSubmit = async () => {
    try {
      const response = await checkUserPwd({ password });
      if (response.status === 200) {
        navigate('/mypage/mod', {
          state: {
            phone: user.phone,
            name: user.name,
            email: user.email,
            team: user.team,
            position: user.position,
          },
        });
      } else {
        handleSnackbarContents('비밀번호를 확인해주세요.', 'warning');
        handleSnackbarOpen();
      }
    } catch (error) {
      console.error('Password check failed:', error);
      handleSnackbarContents('비밀번호 확인 중 오류가 발생했습니다.', 'error');
      handleSnackbarOpen();
    }
    setOpenPasswordDialog(false);
    setPassword('');
  };

  const handleSelected = (value) => {
    setSelected(value);
  };

  const handleStatus = async (selected, isApproved) => {
    const checkOwnStatusErr = selected.filter((item) => item === user.phone);
    if (checkOwnStatusErr.length === 0) {
      for (let i = 0; i < selected.length; i++) {
        const response = await modUserStatus({
          phone: selected[i],
          status: isApproved,
        });
        if (response.status === 200) {
          handleSnackbarContents(
            `${i + 1}건의 임직원 권한을 수정하였습니다`,
            'success'
          );
          handleSnackbarOpen();
          handleSelected([]);
        } else {
          handleSnackbarContents(
            '권한수정 실패. 잠시후 다시 시도해주세요.',
            'error'
          );
          handleSnackbarOpen();
          handleSelected([]);
        }
      }
    } else {
      swal(
        '실패',
        '관리자 자신의 상태값을 변경할 수 없습니다. 자신의 계정 선택을 해제해주세요',
        'error'
      );
      handleSelected([]);
    }
  };

  return (
    <Container maxWidth='md'>
      <ProfileCard elevation={3}>
        <Avatar
          sx={{ width: 100, height: 100, mb: 2, bgcolor: 'secondary.main' }}
          alt={user.name}
          src='/static/images/avatar/1.jpg'
        >
          {user.name.charAt(0)}
        </Avatar>
        <Typography variant='h4' gutterBottom>
          {user.name}
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          {user.position} | {user.team}
        </Typography>
        <Button
          variant='contained'
          color='secondary'
          startIcon={<EditIcon />}
          onClick={handleEditProfile}
          sx={{ mt: 2 }}
        >
          프로필 수정
        </Button>
      </ProfileCard>

      <InfoCard>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant='body1'>
                <strong>이메일:</strong> {user.email}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant='body1'>
                <strong>전화번호:</strong>{' '}
                {user.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </InfoCard>

      {user && user.admin && users && (
        <Box mt={4}>
          <Typography variant='h5' gutterBottom>
            <SecurityIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
            임직원 관리
          </Typography>
          <ManageTotalUser
            users={users}
            handleStatus={handleStatus}
            selected={selected}
            handleSelected={handleSelected}
          />
        </Box>
      )}

      {user && !user.admin && users && (
        <Box mt={4}>
          <Typography variant='h5' gutterBottom>
            <PeopleIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
            임직원 연락망
          </Typography>
          <TotalUserInfo isAdmin={user.admin} users={users} />
        </Box>
      )}

      <Dialog
        open={openPasswordDialog}
        onClose={() => setOpenPasswordDialog(false)}
      >
        <DialogTitle>비밀번호 확인</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='비밀번호'
            type='password'
            fullWidth
            variant='outlined'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPasswordDialog(false)}>취소</Button>
          <Button onClick={handlePasswordSubmit} color='primary'>
            확인
          </Button>
        </DialogActions>
      </Dialog>

      <CustomizedSnackbars
        msg={msg}
        severity={severity}
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
      />
    </Container>
  );
};

export default Mypage;
