import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getInventorySheetsInfo } from '../../../../apis/_axios/fetchData';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactoryIcon from '@mui/icons-material/Factory';
import BuildIcon from '@mui/icons-material/Build';

const StatsCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: 16,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const InventorySummary = () => {
  const [tabValue, setTabValue] = useState(1);
  const [summaryData, setSummaryData] = useState({
    inbound: 0,
    outbound: 0,
    generate: 0,
    used: 0,
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    fetchInventoryData();
  }, [tabValue]);

  const fetchInventoryData = async () => {
    let startDate, endDate;
    const today = new Date();

    switch (tabValue) {
      case 0: // 오늘
        startDate = new Date(today.setHours(0, 0, 0, 0));
        endDate = new Date(today.setHours(23, 59, 59, 999));
        break;
      case 1: // 이번주 (월요일부터 일요일까지)
        startDate = new Date(
          today.setDate(
            today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
          )
        );
        endDate = new Date(today.setDate(today.getDate() - today.getDay() + 7));
        break;
      case 2: // 이번달
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      default:
        startDate = new Date(
          today.setDate(
            today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
          )
        );
        endDate = new Date(today.setDate(today.getDate() - today.getDay() + 7));
    }

    try {
      const response = await getInventorySheetsInfo({
        date_start: formatDate(startDate),
        date_end: formatDate(endDate),
        offset: 0,
        limit: 100000,
      });
      calculateSummary(response.data.message);
    } catch (error) {
      console.error('Error fetching inventory data:', error);
    }
  };

  const calculateSummary = (data) => {
    const summary = {
      inbound: 0,
      outbound: 0,
      generate: 0,
      used: 0,
    };

    data.forEach((item) => {
      if (item.sheet__type in summary) {
        summary[item.sheet__type] += item.quantity;
      }
    });

    setSummaryData(summary);
  };

  const getIcon = (key) => {
    switch (key) {
      case 'inbound':
        return <InventoryIcon sx={{ fontSize: 30, color: 'white' }} />;
      case 'outbound':
        return <LocalShippingIcon sx={{ fontSize: 30, color: 'white' }} />;
      case 'generate':
        return <FactoryIcon sx={{ fontSize: 30, color: 'white' }} />;
      case 'used':
        return <BuildIcon sx={{ fontSize: 30, color: 'white' }} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label='오늘' />
        <Tab label='이번주' />
        <Tab label='이번달' />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {Object.entries(summaryData).map(([key, value], index) => (
            <Grid item xs={6} key={index}>
              <StatsCard>
                <IconWrapper>{getIcon(key)}</IconWrapper>
                <Typography variant='h6' component='div'>
                  {key === 'inbound' && '입고'}
                  {key === 'outbound' && '출고'}
                  {key === 'generate' && '생산'}
                  {key === 'used' && '생산소모'}
                </Typography>
                <Typography
                  variant='h4'
                  component='div'
                  color='primary'
                  fontWeight='bold'
                >
                  {value}
                </Typography>
              </StatsCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default InventorySummary;
