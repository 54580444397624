import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getUserinfo } from '../apis/_axios/fetchData';
import { getSignin } from '../apis/_axios/fetchData';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserInfo = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await getUserinfo();
        setUser(response.data.user_info[0]);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
        localStorage.removeItem('token');
        setUser(null);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  // const login = async (data) => {
  //   try {
  //     const response = await getSignin(data);
  //     if (response.status === 200) {
  //       localStorage.setItem('token', response.data.message);
  //       await fetchUserInfo();
  //       console.log('로그인성공');
  //       return { success: true, message: '로그인 성공' };
  //     } else {
  //       return {
  //         success: false,
  //         message: response.data.Message || '로그인 실패',
  //       };
  //     }
  //   } catch (error) {
  //     console.error('Login error:', error);
  //     return {
  //       success: false,
  //       message: '로그인에 실패하였습니다. 잠시 후 다시 시도해주세요.',
  //     };
  //   }
  // };
  const login = async (data) => {
    try {
      const response = await getSignin(data);
      if (response.code === 'ERR_NETWORK') {
        return {
          success: false,
          message: '로그인에 실패하였습니다. 잠시후 다시 로그인해주세요',
        };
      } else if (response.status === 200) {
        localStorage.setItem('token', response.data.message);
        await fetchUserInfo();
        return { success: true, message: '로그인 성공' };
      } else if (response.data.Message === 'Please check the password.') {
        return {
          success: false,
          message: '로그인 계정정보를 확인해주세요',
        };
      } else if (response.status === 403) {
        return {
          success: false,
          message: '관리자에게 계정 허가를 요청하세요.',
        };
      } else if (
        response.data.Message ===
        'The mobile phone number you entered does not exist.'
      ) {
        return {
          success: false,
          message: '등록되지 않은 계정입니다. 계정발급을 요청해주세요.',
        };
      } else {
        return {
          success: false,
          message: '로그인 실패',
        };
      }
    } catch (error) {
      console.error('Login error:', error);
      return {
        success: false,
        message: '로그인에 실패하였습니다. 잠시 후 다시 시도해주세요.',
      };
    }
  };
  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
