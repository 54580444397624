export const exBlue = "#377CAE";

export const errRed = "#e85255";

export const formGray = "#E0E0E0";

export const inputGray = "#f0f3ff";

export const normalWhite = "#FFFFFF";

// export const backgroundblue = '#bbd7eb';

export const backgroundblue = "#eaebf0";


export const menuGray = "#eaebf0";

export const btnGray = "#666666";

export const btnBlue = "#0073CF";

export const logibearBrown = "#2c1d00";
