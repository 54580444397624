import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const DateTimeCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: 16,
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const TimeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));

export const CurrentDateTime = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const days = ['일', '월', '화', '수', '목', '금', '토'];
  const day = days[currentTime.getDay()];
  const date = currentTime.toLocaleDateString('ko-KR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const time = currentTime.toLocaleTimeString('ko-KR', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <DateTimeCard elevation={3}>
      <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
        {date} ({day})
      </Typography>
      <TimeBox>
        <AccessTimeIcon sx={{ fontSize: 30, marginRight: 1 }} />
        <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
          {time}
        </Typography>
      </TimeBox>
    </DateTimeCard>
  );
};

export default CurrentDateTime;
