import { Box, Chip, Stack } from '@mui/material';
import React from 'react';
import { GrayButton } from '../StyledButton/StyledButton';
import { formGray, inputGray } from '../../Style/Colors/Colors';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

export const SerialsDialog = ({ type, serials, handleSerialsDialogClose }) => {
  const navigate = useNavigate();
  const findSerialDesc = (serial) => {
    swal({
      title: '안내',
      text: `${serial} 제품의 시리얼 이력을 조회하시겠습니까?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willCheck) => {
      if (willCheck) {
        navigate('/serials', { state: { serial: serial } });
      }
    });
    // if (window.confirm(`${serial} 제품의 시리얼 이력을 조회하시겠습니까?`)) {
    //   navigate('/serials', { state: { serial: serial } });
    // }
  };
  return (
    <Stack
      alignItems={'center'}
      spacing={2}
      sx={{
        position: 'realative',
        minWidth: '600px',
        minHeight: '600px',
        padding: '32px',
      }}
    >
      <span style={{ fontSize: '2rem', fontWeight: '600' }}>
        시리얼넘버 조회
      </span>
      <Stack
        spacing={2}
        id='serialBox'
        padding='16px'
        sx={{
          width: '100%',
          borderRadius: '8px',
          backgroundColor: `${inputGray}`,
          minHeight: '250px',
        }}
      >
        {type === 'generate' ? (
          <Box
            direction={'row'}
            sx={{
              whiteSpace: 'pre-line',
              maxHeight: '250px',
              overflow: 'auto',
            }}
          >
            {serials &&
              serials.map((serial, idx) => (
                <div
                  key={serial.generate_serial_code}
                  style={
                    serial.used_serial_code.length > 0
                      ? {
                          display: 'inline-block',
                          borderRadius: '16px',
                          border: `1px solid ${formGray}`,
                          marginBottom: '16px',
                        }
                      : {
                          display: 'inline-block',
                        }
                  }
                >
                  <Stack direction={'row'}>
                    {/* {serial.used_serial_code.length > 0 && (
                    <Stack padding={'8px 0 0 8px'}>
                      <span style={{ fontSize: '0.8rem' }}>
                        {idx + 1}번째 생산물 :
                      </span>
                    </Stack>
                  )} */}
                    <Chip
                      label={serial.generate_serial_code}
                      onClick={() =>
                        findSerialDesc(serial.generate_serial_code)
                      }
                      sx={
                        serial.used_serial_code.length > 0
                          ? {
                              margin: '3px',
                              userSelect: 'unset',
                              backgroundColor: '#1666de',
                              color: 'white',
                            }
                          : { margin: '3px', userSelect: 'unset' }
                      }
                    />
                  </Stack>
                  {serial.used_serial_code.map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      onClick={() => findSerialDesc(item)}
                      sx={{ margin: '3px', userSelect: 'unset' }}
                    />
                  ))}
                </div>
              ))}
          </Box>
        ) : (
          <Box
            direction={'row'}
            sx={{
              whiteSpace: 'pre-line',
              maxHeight: '250px',
              overflow: 'auto',
            }}
          >
            {serials &&
              serials.map((serial) => (
                <Chip
                  key={serial}
                  label={serial}
                  onClick={() => findSerialDesc(serial)}
                  sx={{ margin: '3px', userSelect: 'unset' }}
                />
              ))}
          </Box>
        )}
      </Stack>
      <GrayButton onClick={handleSerialsDialogClose}>닫기</GrayButton>
    </Stack>
  );
};
