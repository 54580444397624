import React, { useEffect, useState } from 'react';
import CustomizedSnackbars from '../../../../components/CustomizedSnackbars/CustomizedSnackbars';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormControl, Stack } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';
import {
  getUserinfo,
  modUserInfoByAdmin,
} from '../../../../apis/_axios/fetchData';
import { BlueButton } from '../../../../components/StyledButton/StyledButton';
import StyledInputLabel from '../../../../components/StyledInputLabel/StyledInputLabel';

import { ErrText } from '../../../../components/ErrText/ErrText';
import { backgroundblue, formGray } from '../../../../Style/Colors/Colors';
import { StyledInput } from '../../../../components/StyledInput/StyledInput';

export const ModUserInfo = ({ isMobile, handleTitle }) => {
  const [user, setUser] = useState();
  useEffect(() => {
    getUserinfo().then((res) => setUser(res.data.user_info[0]));
  }, []);
  useEffect(() => {
    handleTitle({ titleName: '회원정보수정', user: user });
  }, [user]);

  const location = useLocation();
  const navigate = useNavigate();
  //스낵바UI이벤트
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [msg, setMsg] = useState();
  const [severity, setSeverity] = useState();
  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'escapeKeyDown') {
      return;
    }
    setSnackbarOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ criteriaMode: 'all' });

  const onSubmit = async (data) => {
    if (data.password === data.checkPwd) {
      const response = await modUserInfoByAdmin(data, data.phone);
      if (response.status === 200) {
        navigate('/mypage');
      } else if (
        response.data.message ===
        '비밀번호 정규표현식, 8자 이상 16자 이하, 소문자, 숫자 최소 하나 사용 '
      ) {
        handleSnackbarOpen();
        setMsg('영어와 숫자가 조합된 비밀번호를 등록해주세요');
        setSeverity('warning');
      } else {
        handleSnackbarOpen();
        setMsg('회원정보 수정실패. 잠시후 다시 시도해주세요.');
        setSeverity('error');
      }
    } else {
      handleSnackbarOpen();
      setMsg('비밀번호와 비밀번호확인이 일치하지 않습니다.');
      setSeverity('error');
    }
  };
  return (
    <Stack
      sx={
        isMobile
          ? {
              width: '100vw',
              height: '100vh',
              paddingTop: '1rem',
              marginLeft: '-29px',
              backgroundColor: backgroundblue,
              alignItems: 'center',
            }
          : {
              width: '100vw',
              height: '100vh',
              paddingTop: '1rem',
              marginLeft: '-120px',
              backgroundColor: backgroundblue,
              alignItems: 'center',
            }
      }
    >
      {snackbarOpen && (
        <CustomizedSnackbars
          msg={msg}
          severity={severity}
          snackbarOpen={snackbarOpen}
          handleSnackbarOpen={handleSnackbarOpen}
          handleSnackbarClose={handleSnackbarClose}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          spacing={2}
          sx={{
            width: '20rem',
            direction: 'column',
            padding: '20px',
            borderRadius: '10px',
            border: `3px solid ${formGray}`,
            backgroundColor: '#FFFFFF',
          }}
        >
          <span style={{ fontSize: '1.5rem', fontWeight: 700 }}>
            회원정보수정
          </span>
          <span style={{ width: '100%', fontSize: '1rem', fontWeight: 700 }}>
            필수정보입력
          </span>
          <FormControl>
            <StyledInputLabel htmlFor='phone'>
              *휴대전화번호(변경불가)
            </StyledInputLabel>
            <StyledInput
              disableUnderline
              id='phone'
              type='text'
              sx={{
                marginBottom: '10px',
                borderRadius: '5px',
                backgroundColor: 'lightGray',
              }}
              {...register('phone', {
                required: { value: true, message: '필수입력값입니다' },

                minLength: {
                  value: 11,
                  message: "'-'를 제외한 휴대전화번호 11자리를 입력해주세요",
                },
                maxLength: {
                  value: 11,
                  message: "'-'를 제외한 휴대전화번호 11자리를 입력해주세요",
                },
              })}
              defaultValue={location.state.phone}
              readOnly
            />
            <ErrorMessage
              errors={errors}
              name='phone'
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <ErrText key={type}>{message}</ErrText>
                ))
              }
            />
          </FormControl>
          <FormControl>
            <StyledInputLabel htmlFor='checkPwd'>*성명</StyledInputLabel>
            <StyledInput
              disableUnderline
              id='name'
              type='text'
              {...register('name', {
                required: { value: true, message: '필수입력값입니다' },

                minLength: { value: 2, message: '2자 이상 입력해주세요' },
                maxLength: { value: 10, message: '11자 미만으로 입력해주세요' },
              })}
              defaultValue={location.state.name}
            />
            <ErrorMessage
              errors={errors}
              name='name'
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <ErrText key={type}>{message}</ErrText>
                ))
              }
            />
          </FormControl>
          <FormControl>
            <StyledInputLabel htmlFor='user-pwd'>*비밀번호</StyledInputLabel>
            <StyledInput
              disableUnderline
              id='user-pwd'
              type='password'
              sx={{ marginBottom: '10px' }}
              {...register('password', {
                required: { value: true, message: '필수입력값입니다' },

                minLength: { value: 8, message: '8자 이상 입력해주세요' },
                maxLength: { value: 15, message: '15자 이하로 입력해주세요' },
              })}
            />
            <ErrorMessage
              errors={errors}
              name='user-pwd'
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <ErrText key={type}>{message}</ErrText>
                ))
              }
            />
          </FormControl>
          <FormControl>
            <StyledInputLabel htmlFor='checkPwd'>
              *비밀번호확인
            </StyledInputLabel>
            <StyledInput
              disableUnderline
              id='checkPwd'
              type='password'
              sx={{ marginBottom: '10px' }}
              {...register('checkPwd', {
                required: { value: true, message: '필수입력값입니다' },
                minLength: { value: 8, message: '8자 이상 입력해주세요' },
                maxLength: { value: 15, message: '15자 이하로 입력해주세요' },
              })}
            />
            <ErrorMessage
              errors={errors}
              name='checkPwd'
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <ErrText key={type}>{message}</ErrText>
                ))
              }
            />
          </FormControl>
          <span style={{ width: '100%', fontSize: '1rem', fontWeight: 700 }}>
            추가정보입력
          </span>
          <FormControl>
            <StyledInputLabel htmlFor='email'>이메일</StyledInputLabel>
            <StyledInput
              disableUnderline
              id='email'
              type='text'
              sx={{ marginBottom: '10px' }}
              {...register('email', {
                pattern: {
                  value: /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                  message: '올바른 이메일형식으로 작성해주세요',
                },
              })}
              defaultValue={location.state.email}
            />

            <ErrorMessage
              errors={errors}
              name='email'
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <ErrText key={type}>{message}</ErrText>
                ))
              }
            />
          </FormControl>
          <FormControl>
            <StyledInputLabel htmlFor='team'>부서</StyledInputLabel>
            <StyledInput
              disableUnderline
              id='team'
              type='text'
              sx={{ marginBottom: '10px' }}
              {...register('team', {
                minLength: { value: 2, message: '2자 이상 입력해주세요' },
                maxLength: { value: 10, message: '11자 미만으로 입력해주세요' },
              })}
              defaultValue={location.state.team}
            />
            <ErrorMessage
              errors={errors}
              name='team'
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <ErrText key={type}>{message}</ErrText>
                ))
              }
            />
          </FormControl>
          <FormControl>
            <StyledInputLabel htmlFor='position'>직책/직급</StyledInputLabel>
            <StyledInput
              disableUnderline
              id='position'
              type='text'
              sx={{ marginBottom: '10px' }}
              {...register('position', {
                minLength: { value: 2, message: '2자 이상 입력해주세요' },
                maxLength: { value: 10, message: '11자 미만으로 입력해주세요' },
              })}
              defaultValue={location.state.position}
            />
            <ErrorMessage
              errors={errors}
              name='position'
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <ErrText key={type}>{message}</ErrText>
                ))
              }
            />
          </FormControl>
          <BlueButton type='submit'>회원정보수정</BlueButton>
        </Stack>
      </form>
    </Stack>
  );
};
