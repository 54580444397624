import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Box,
  CssBaseline,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Stack,
  Tooltip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import LogoutIcon from '@mui/icons-material/Logout';
import InventoryIcon from '@mui/icons-material/Inventory';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Link } from 'react-router-dom';
import MenuWrapper from './MenuWrapper/MenuWrapper';
import { useAuth } from '../../hooks/useAuth';

const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const sideMenuItems = [
  { name: '마이페이지', link: '/mypage', icon: <AccountCircleIcon /> },
  { name: '안전재고 현황', link: '/safe-stock', icon: <InventoryIcon /> },
  { name: '입출고현황', link: '/inventory', icon: <SyncAltOutlinedIcon /> },
  { name: '재고현황', link: '/stock', icon: <WarehouseIcon /> },
  {
    name: '시리얼관리',
    link: '/trace-mobile',
    icon: <ScreenSearchDesktopIcon />,
  },
  { name: '로그아웃', link: '/signout', icon: <LogoutIcon /> },
];

const bottomMenuItems = [
  { name: '재고현황', link: '/stock', icon: <WarehouseIcon /> },
  { name: '안전재고', link: '/safe-stock', icon: <InventoryIcon /> },
  { name: '시리얼입력', link: '/trace-mobile', icon: <QrCodeScannerIcon /> },
  { name: '로그아웃', link: '/signout', icon: <LogoutIcon /> },
];

export default function Menu({ title }) {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        open={open}
        elevation={0}
        sx={{
          backgroundColor: '#1976d2',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        }}
      >
        <Toolbar sx={{ minHeight: '64px' }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ width: '100%' }}
          >
            <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{ fontWeight: 'bold' }}
            >
              {title && title.titleName}
            </Typography>
            <Tooltip title='마이페이지'>
              <IconButton
                color='inherit'
                component={Link}
                to='/mypage'
                sx={{
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.2)',
                  },
                }}
              >
                <AccountCircleIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: 'linear-gradient(180deg, #1565c0 0%, #0d47a1 100%)',
          },
        }}
        variant='temporary'
        anchor='left'
        open={open}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
          <IconButton onClick={handleDrawerToggle} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ padding: '16px', color: 'white' }}>
          <Stack direction={'row'} alignItems='end' sx={{ minHeight: '25px' }}>
            <span style={{ fontSize: '1rem', fontWeight: '700' }}>
              {user && user.name && user.name}
            </span>
            <span style={{ fontSize: '0.8rem' }}>
              {user && user && user.position && user.position} 님
            </span>
          </Stack>
          <span style={{ fontSize: '0.8rem' }}>오늘도 좋은 하루 보내세요</span>
        </Box>
        <MenuWrapper
          open={open}
          menu={sideMenuItems}
          onItemClick={handleDrawerToggle}
        />
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {/* Main content goes here */}
      </Box>
    </Box>
  );
}
