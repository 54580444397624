import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { Box, CircularProgress } from '@mui/material';
const PrivateRoute = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress size={60} />
      </Box>
    ); // 또는 로딩 스피너 컴포넌트
  }

  return user ? <Outlet /> : <Navigate to='/signin' replace />;
};

export default PrivateRoute;
