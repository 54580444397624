import React, { useEffect } from 'react';
import { Box, Container, Typography, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import CurrentDateTime from './component/CurrentDateTime/CurrentDateTime';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactoryIcon from '@mui/icons-material/Factory';
import InventorySummary from './component/InventorySummary/InventorySummary';

const StatsCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: 16,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const Main = ({ handleTitle, user }) => {
  useEffect(() => {
    handleTitle({ titleName: '메인' });
  }, [user, handleTitle]);

  return (
    <Container maxWidth='sm' sx={{ pt: 1, pb: 4 }}>
      <CurrentDateTime />
      <Box sx={{ mt: 2 }}>
        <Typography variant='h5' component='h2' gutterBottom>
          재고 현황
        </Typography>
        <InventorySummary />
      </Box>
    </Container>
  );
};

export default Main;
