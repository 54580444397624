import React from 'react';
import { Box, Typography, Divider, Chip, Button } from '@mui/material';
import { Close, GetApp } from '@mui/icons-material';

export const ProductInfoDialog = ({
  selectedProduct,
  stockQtt,
  setIsDrawerOpen,
}) => {
  const product = selectedProduct.data.message[0];
  const stockInfo = stockQtt.find(
    (item) => item.product_code === product.product_code
  );
  console.log(stockQtt);

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant='h6'>{product.name}</Typography>
        <Close onClick={() => setIsDrawerOpen(false)} cursor={'pointer'} />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ mb: 2 }}>
        <Typography variant='subtitle2' color='textSecondary'>
          제품명
        </Typography>
        <Typography variant='body1'>{product.product_name}x</Typography>
        <Typography variant='caption' color='textSecondary'>
          {' '}
          {product.product_code}
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant='subtitle2' color='textSecondary'>
          제품 그룹
        </Typography>
        <Typography variant='body1'>{product.product_group_name}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant='subtitle2' color='textSecondary'>
          현재 재고
        </Typography>
        <Typography variant='h5'>{stockInfo?.quantity || 0}</Typography>
        {stockInfo && stockInfo.quantity < product.safe_quantity && (
          <Chip
            label='안전재고 미달'
            color='error'
            size='small'
            sx={{ mt: 1 }}
          />
        )}
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant='subtitle2' color='textSecondary'>
          안전 재고
        </Typography>
        <Typography variant='body1'>
          {product.safe_quantity || '미설정'}
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant='subtitle2' color='textSecondary'>
          고정 입고처
        </Typography>
        <Typography variant='body1'>
          {product.company__name || '미지정'}
        </Typography>
      </Box>
    </Box>
  );
};
