import React from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const EventPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        <Card elevation={3}>
          <CardMedia
            component="img"
            height="140"
            image="/api/placeholder/400/140"
            alt="이벤트 배너"
          />
          <CardContent>
            <Typography variant="h4" component="div" gutterBottom>
              특별 이벤트!
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              QR 코드를 스캔해주셔서 감사합니다. 귀하께서는 특별 이벤트에
              참여하실 수 있는 기회를 얻으셨습니다!
            </Typography>
            <Typography variant="h6" gutterBottom>
              이벤트 내용:
            </Typography>
            <Typography variant="body1" paragraph>
              1. 추첨을 통해 10명에게 상품권 10만원 증정
            </Typography>
            <Typography variant="body1" paragraph>
              2. 모든 참여자에게 온라인 쿠폰 5,000원 지급
            </Typography>
            <Box mt={3}>
              <Button variant="contained" color="primary" fullWidth>
                이벤트 참여하기
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  );
};

export default EventPage;
