import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Grid,
  Paper,
  Box,
  Button,
  TextField,
  DialogActions,
  Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  getSerialOptionalTitle,
  modSerialItemInfo,
  delSerialItemInfo,
} from '../../../apis/_axios/fetchData';
import swal from 'sweetalert';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
const SerialDetailDialog = ({
  open,
  onClose,
  serialData,
  onUpdate,
  productLines,
  productFound,
  onCopy,
}) => {
  const [additionalTitles, setAdditionalTitles] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  useEffect(() => {
    fetchAdditionalTitles();
    setEditedData(serialData);
  }, [serialData]);
  const productStatusOptions = ['신품', '철거', '재설치', '기타'];

  const fetchAdditionalTitles = async () => {
    try {
      const response = await getSerialOptionalTitle();
      if (response && response.data && response.data.message) {
        setAdditionalTitles(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching additional titles:', error);
    }
  };
  // 수정: usedAuxiliaryItems를 가져오는 로직
  const usedAuxiliaryItems = useMemo(() => {
    let items = [];
    if (serialData.product_line_id) {
      const productLine = productLines.find(
        (line) => line.id === Number(serialData.product_line_id)
      );
      items = productLine ? productLine.used_auxiliary_items : [];
    } else {
      const product = productFound.find(
        (product) =>
          product.product_code === serialData.serial_number.slice(0, -11)
      );
      items = product ? product.used_auxiliary_items : [];
    }
    return items;
  }, [serialData, productLines, productFound]);
  // ★ 제품 정보를 가져오는 함수 추가
  const productInfo = useMemo(() => {
    if (serialData.product_line_id) {
      const productLine = productLines.find(
        (line) => line.id === Number(serialData.product_line_id)
      );
      return productLine ? ` ${productLine.name}` : '알 수 없는 제품라인';
    } else {
      //serialData.serial_number.slice(0, -11)의 값이 productFound.product_code에 있는지 확인한 후 있으면 productFound.product_name을 리턴
      const product = productFound.find(
        (product) =>
          product.product_code === serialData.serial_number.slice(0, -11)
      );
      console.log(product);

      return product ? `${product.name}` : '알 수 없는 제품';
    }
  }, [serialData, productLines, productFound]);
  // 수정: groupedData 객체 업데이트
  const groupedData = useMemo(() => {
    const baseColumns = [
      'serial_number',
      'product_serial_number',
      'po',
      'handler',
      'writer',
      'delivery_site',
      'delivery_date',
      'delivery_status',
      'product_status',
      'production_date',
      'fab',
      'division',
    ];

    const allFields = [...new Set([...baseColumns, ...usedAuxiliaryItems])];

    const groups = {
      '기본 정보': allFields.filter((field) =>
        [
          'serial_number',
          'product_serial_number',
          'po',
          'handler',
          'writer',
        ].includes(field)
      ),
      '납품 정보': allFields.filter((field) =>
        [
          'delivery_site',
          'delivery_date',
          'delivery_status',
          'product_status',
        ].includes(field)
      ),
      '생산 정보': allFields.filter((field) =>
        ['production_date', 'fab', 'division'].includes(field)
      ),
      '추가 정보': allFields.filter(
        (field) =>
          !baseColumns.includes(field) || field.startsWith('additional_item_')
      ),
    };

    // 빈 그룹 제거
    return Object.fromEntries(
      Object.entries(groups).filter(([_, fields]) => fields.length > 0)
    );
  }, [usedAuxiliaryItems]);
  const handleCopy = () => {
    const excludeFields = [
      'created_at',
      'updated_at',
      'product_serial_number',
      'serial_number',
      'serial_managed_item_id',
      'serial_managed_item',
      'updated_status',
      'id',
      'product_line_name',
      'product_line_id',
    ];

    const copiedData = Object.keys(serialData).reduce((acc, key) => {
      if (!excludeFields.includes(key)) {
        acc[key] = serialData[key];
      }
      return acc;
    }, {});

    onCopy(copiedData); // 부모 컴포넌트로 복사된 데이터 전달
  };
  if (!serialData) return null;

  const headerMap = {
    serial_number: '재고S/N',
    product_serial_number: '제품S/N',
    po: 'PO',
    handler: '담당자',
    fab: 'FAB',
    division: 'DIVISION',
    production_date: '생산일',
    delivery_site: '납품처',
    delivery_date: '납품일',
    delivery_status: '납품상태',
    writer: '작성자',
    updated_at: '수정일',
    installation_date: '설치일',
    fab_installation: 'FAB-설치',
    bay_installation: 'BAY-설치',
    division_process: 'DIVISION-공정',
    equipment_company: '장비사',
    model: '모델',
    equipment_name: '장비명',
    chamber: '챔버',
    gv_company: 'GV사',
    gv_installation_company: 'GV설치사',
    dry_pump_manufacturer: '건조펌프 제조사',
    dry_pump_model: '건조펌프 모델',
    ct_length: 'CT길이',
    pv: 'PV',
    sv: 'SV',
    dip: 'DIP',
    remark: '적요',
    product_status: '제품상태',
  };

  const dateFields = ['delivery_date', 'production_date', 'installation_date'];

  const handleInputChange = (event, newValue, isDatePicker = false) => {
    if (isDatePicker) {
      const { name, value } = event;
      setEditedData((prev) => ({
        ...prev,
        [name]: value ? dayjs(value).format('YYYY-MM-DDT00:00:00') : null,
      }));
    } else if (event && event.target) {
      const { name, value } = event.target;
      setEditedData((prev) => ({ ...prev, [name]: value }));
    } else {
      // Autocomplete의 경우
      setEditedData((prev) => ({ ...prev, product_status: newValue }));
    }
  };

  const handleEdit = async () => {
    if (editMode) {
      try {
        const response = await modSerialItemInfo(serialData.id, editedData);
        if (response.status === 200) {
          swal('성공', '시리얼 정보가 업데이트되었습니다.', 'success');
          await onUpdate(); // onUpdate를 비동기 함수로 처리
          setEditMode(false);
          onClose(); // 다이얼로그를 닫고 이전 화면으로 돌아갑니다.
        } else {
          swal('실패', '수정에 실패했습니다. 다시 시도해주세요.', 'error');
        }
      } catch (error) {
        console.error('Error updating serial:', error);
        swal('오류', '수정 중 오류가 발생했습니다.', 'error');
      }
    } else {
      setEditMode(true);
    }
  };

  const handleDelete = async () => {
    swal({
      title: '정말로 삭제하시겠습니까?',
      text: '이 작업은 되돌릴 수 없습니다!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await delSerialItemInfo(serialData.id);
          if (response.status === 200) {
            swal('시리얼 정보가 삭제되었습니다.', {
              icon: 'success',
            });
            onUpdate();
            onClose();
          } else {
            swal('삭제에 실패했습니다.', {
              icon: 'error',
            });
          }
        } catch (error) {
          console.error('Error deleting serial:', error);
          swal('삭제 중 오류가 발생했습니다.', {
            icon: 'error',
          });
        }
      }
    });
  };

  const renderDetailItem = (key) => {
    if (['id', 'product_line_id', 'serial_managed_item_id'].includes(key)) {
      return null;
    }

    let label = headerMap[key] || key;
    if (key.startsWith('additional_item_')) {
      const index = key.split('_').pop();
      label = additionalTitles[index] || `추가항목 ${index}`;
    }

    let displayValue =
      serialData[key] != null ? serialData[key].toString() : '-';
    if (dateFields.includes(key) && displayValue && displayValue !== '-') {
      displayValue = displayValue.split('T')[0];
    }

    return (
      <Grid item xs={12} sm={6} key={key}>
        <Box sx={{ mb: 1 }}>
          <Typography variant='caption' color='text.secondary'>
            {label}
          </Typography>
          {editMode && key !== 'serial_number' ? (
            dateFields.includes(key) ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={editedData[key] ? dayjs(editedData[key]) : null}
                  onChange={(newValue) =>
                    handleInputChange(
                      { name: key, value: newValue },
                      null,
                      true
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size='small'
                      margin='dense'
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '40px',
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            ) : key === 'product_status' ? (
              <Autocomplete
                options={productStatusOptions}
                value={editedData[key] || null}
                onChange={(event, newValue) =>
                  handleInputChange(null, newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name={key}
                    size='small'
                    margin='dense'
                  />
                )}
              />
            ) : (
              <TextField
                fullWidth
                name={key}
                value={editedData[key] || ''}
                onChange={handleInputChange}
                size='small'
                margin='dense'
              />
            )
          ) : (
            <Typography variant='body2'>{displayValue}</Typography>
          )}
        </Box>
      </Grid>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle sx={{ m: 0, p: 2, bgcolor: 'primary.main', color: 'white' }}>
        시리얼 상세 정보
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: '#f5f5f5', pb: 7 }}>
        {Object.entries(groupedData).map(([group, keys]) => (
          <Paper key={group} elevation={2} sx={{ p: 2, mb: 2 }}>
            <Typography variant='h6' gutterBottom>
              {group === '기본 정보' ? productInfo : group}
            </Typography>
            <Grid container spacing={2}>
              {keys.map((key) => renderDetailItem(key))}
            </Grid>
          </Paper>
        ))}
      </DialogContent>
      <DialogActions
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: 'background.paper',
          p: 2,
        }}
      >
        <Button onClick={handleCopy} color='info' startIcon={<FileCopyIcon />}>
          복사
        </Button>
        <Button onClick={handleEdit} color='primary' startIcon={<EditIcon />}>
          {editMode ? '저장' : '수정'}
        </Button>
        <Button onClick={handleDelete} color='error' startIcon={<DeleteIcon />}>
          삭제
        </Button>
        <Button onClick={onClose} color='inherit'>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SerialDetailDialog;
