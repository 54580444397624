import { Autocomplete, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  getSerialEtcTitle,
  regSerialEtcContent,
} from '../../../../apis/_axios/fetchData';
import {
  BlueButton,
  GrayButton,
} from '../../../../components/StyledButton/StyledButton';
import swal from 'sweetalert';

export const RegEtcContentsTab = ({
  handleSerialMngDialogClose,
  handleSnackbarContents,
  handleSnackbarOpen,
  searchWord,
}) => {
  const [serialEtcTitles, setSerialEtcTitles] = useState({
    id: '',
    title: '',
    status: true,
  });
  useEffect(() => {
    getSerialEtcTitle().then((res) =>
      setSerialEtcTitles(
        res.data.message.filter((item) => item.status === true)
      )
    );
  }, []);

  const { setValue, handleSubmit } = useForm({
    criteriaMode: 'all',
  });
  useEffect(() => {
    setValue('serial_code', searchWord);
  }, []);

  const onSubmit = async (data) => {
    if (!data.title_id > 0) {
      swal('실패', '상태를 입력해주세요', 'error');
    } else if (!data.serial_code.length > 0) {
      swal('실패', '시리얼 번호를 입력해주세요', 'error');
    } else {
      const response = await regSerialEtcContent(data);
      if (response.status === 200) {
        handleSnackbarContents('등록에 성공하였습니다.', 'success');
        handleSnackbarOpen();
        handleSerialMngDialogClose();
      } else {
        handleSnackbarContents('등록 실패. 잠시후 다시 시도해주세요.', 'error');
        handleSnackbarOpen();
      }
    }
  };

  return (
    <Stack padding='8px'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} mb={5}>
          <span style={{ width: '400px', fontWeight: '600' }}>
            시리얼 : {searchWord}
          </span>
          {/* <TextField
            label='시리얼번호'
            size='small'
            sx={{ width: '100%', minWidth: '300px' }}
            onChange={(e) => setValue('serial_code', e.target.value)}
            
          /> */}
          {serialEtcTitles && serialEtcTitles.length > 0 && (
            <Autocomplete
              id='single-tag'
              autoHighlight
              options={serialEtcTitles}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField {...params} label='상태' size='small' />
              )}
              sx={{ width: '100%' }}
              onChange={(e, newValue) => setValue('title_id', newValue.id)}
            />
          )}
          <TextField
            label='상세 처리내용'
            size='small'
            onChange={(e) => setValue('contents', e.target.value)}
          />
        </Stack>
        <Stack direction={'row'} spacing={2}>
          <GrayButton onClick={handleSerialMngDialogClose}>취소</GrayButton>
          <BlueButton type='submit'>등록</BlueButton>
        </Stack>
      </form>
    </Stack>
  );
};
