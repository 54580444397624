export const classifyTypes = (types) => {
  if (types === 'inbound') {
    return '입고';
  } else if (types === 'outbound') {
    return '출고';
  } else if (types === 'new') {
    return '신규등록';
  } else if (types === 'generate') {
    return '생산';
  } else if (types === 'used') {
    return '생산소모';
  } else if (types === 'return') {
    return '반품';
  } else {
    return types;
  }
};
