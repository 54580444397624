import React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

export default function CustomizedSnackbars({
  msg,
  severity,
  snackbarOpen,
  handleSnackbarClose,
}) {
  function SlideTransition(props) {
    return <Slide {...props} direction='up' />;
  }
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
        onClose={handleSnackbarClose}
        sx={{ zIndex: '99999999' }}
      >
        <MuiAlert elevation={6} variant='filled' severity={severity}>
          <span style={{ fontSize: '1.5rem' }}>{msg}</span>
        </MuiAlert>
      </Snackbar>
      {/* 
      스낵바 예시
      <Alert severity='error'>This is an error message!</Alert>
      <Alert severity='warning'>This is a warning message!</Alert>
      <Alert severity='info'>This is an information message!</Alert>
      <Alert severity='success'>This is a success message!</Alert> */}
    </Stack>
  );
}
