import React, { useRef, useState } from 'react';
import MaterialTable from '@material-table/core';
import { Dialog, Paper, Stack } from '@mui/material';
import { BlueButton, GrayButton } from '../StyledButton/StyledButton';
import { getProductInfoWithFilter } from '../../apis/_axios/fetchData';
import swal from 'sweetalert';

export const SearchProductDialog = ({
  handleSearchProductDlgClose,
  isSearchProductDlgOpened,
  selectProduct,
  setValue,
  movementType,
}) => {
  const searchText = useRef();
  const confirmProduct = (product) => {
    if (movementType === 'generate') {
      selectProduct(product);
      setValue && setValue('set_product_code', product.product_code);
      handleSearchProductDlgClose();
    } else {
      selectProduct(product);
      setValue && setValue('product_code', product.product_code);
      handleSearchProductDlgClose();
    }
  };
  const removeProductInfo = () => {
    if (movementType === 'generate') {
      selectProduct(null);
      setValue && setValue('set_product_code', null);
      handleSearchProductDlgClose();
    } else {
      selectProduct(null);
      setValue && setValue('product_code', null);
      handleSearchProductDlgClose();
    }
  };
  const [findedProducts, setFindedProducts] = useState([]);

  const search = async () => {
    // if (searchText.current.value.trim().length === 0) {
    //   swal('실패','공백을 제외한 1자 이상의 검색어를 입력해주세요','error');
    // } else {
    //   let result = [];
    //   const productNameFinded = await getProductInfoWithFilter(
    //     'name',
    //     searchText.current.value
    //   );
    //   const productKeywordFinded = await getProductInfoWithFilter(
    //     'keyword',
    //     searchText.current.value
    //   );
    //   result.push(
    //     productNameFinded.data.message,
    //     productKeywordFinded.data.message
    //   );
    //   if (movementType === 'generate') {
    //     setFindedProducts(
    //       [...new Set(result.flat().map(JSON.stringify))]
    //         .map(JSON.parse)
    //         .filter((item) => item.is_set === true)
    //     );
    //   } else {
    //     setFindedProducts(
    //       [...new Set(result.flat().map(JSON.stringify))].map(JSON.parse)
    //     );
    //   }
    // }
    let result = [];
    const productNameFinded = await getProductInfoWithFilter(
      'name',
      searchText.current.value
    );
    const productKeywordFinded = await getProductInfoWithFilter(
      'keyword',
      searchText.current.value
    );
    result.push(
      productNameFinded.data.message,
      productKeywordFinded.data.message
    );
    if (movementType === 'generate') {
      setFindedProducts(
        [...new Set(result.flat().map(JSON.stringify))]
          .map(JSON.parse)
          .filter((item) => item.is_set === true)
      );
    } else {
      setFindedProducts(
        [...new Set(result.flat().map(JSON.stringify))].map(JSON.parse)
      );
    }
  };
  return (
    <Dialog
      onClose={handleSearchProductDlgClose}
      open={isSearchProductDlgOpened}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ minWidth: '500px' }}
      >
        <span
          style={{ fontSize: '1.5rem', fontWeight: '600', padding: '16px' }}
        >
          {movementType === 'generate' ? '세트제품 찾기' : '제품 찾기'}
        </span>
        <Stack p='16px' direction={'row'} spacing={2}>
          <input
            type='text'
            placeholder='검색어를 입력해주세요'
            style={{
              borderRadius: '2px',
              border: '1px solid #c8cad0',
              height: '32px',
              marginRight: '4px',
              padding: '0 8px',
              fontWeight: 'normal !important',
            }}
            ref={searchText}
            value={searchText.target}
          />
          <button variant='outlined' onClick={search} sx={{ width: '30%' }}>
            검색
          </button>
        </Stack>
      </Stack>
      <Stack
        alignItems={'center'}
        sx={{ minWidth: '500px', minHeight: '600px', position: 'relative' }}
      >
        <Stack alignItems={'center'} sx={{ minHeight: '500px' }}>
          <MaterialTable
            sx={{ minWidth: '500px', minHeight: '300px' }}
            columns={[
              { title: '품목코드', field: 'product_code', width: '20%' },
              { title: '품목그룹', field: 'productgroup_name', width: '30%' },
              { title: '제품명', field: 'name', width: '50%' },
            ]}
            data={findedProducts}
            onRowClick={(evt, selectedRow) => confirmProduct(selectedRow)}
            options={{
              search: false,
              toolbar: false,
              padding: 'dense',
              showTitle: false,
              pageSize: 10,
              pageSizeOptions: [10],
            }}
            localization={{
              body: {
                emptyDataSourceMessage: '표시할 정보가 없습니다',
              },
              pagination: {
                labelRowsPerPage: '',
                labelRowsSelect: '',
              },
            }}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
          />
        </Stack>
        <Stack direction='row' spacing={2} sx={{ width: '90%' }}>
          <GrayButton onClick={removeProductInfo}>취소</GrayButton>
          <BlueButton onClick={handleSearchProductDlgClose}>확인</BlueButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
