import { Box, useMediaQuery } from "@mui/material";
import React, { useCallback, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Menu from "./components/Menu/Menu";
import { InventoryInfo } from "./pages/InventoryInfo/InventoryInfo";
import { Main } from "./pages/Main/Main";
import { ModMyInfo } from "./pages/Mypage/Component/ModMyInfo/ModMyInfo";
import { ModUserInfo } from "./pages/Mypage/Component/ModUserInfo/ModUserInfo";
import { Mypage } from "./pages/Mypage/Mypage";
import { NotFound } from "./pages/NotFound/NotFound";
import { StockInfo } from "./pages/StockInfo/StockInfo";
import { ManageSerials } from "./pages/ManageSerials/ManageSerials";
import EnhancedSerialManagerForMobile from "./pages/EnhancedSerialManagerForMobile/EnhancedSerialManagerForMobile";
import SafeStock from "./pages/SafeStock/SafeStock";
import MobileOnlyAccess from "./pages/MobileOnlyAccess/MobileOnlyAccess";
import { useAuth } from "./hooks/useAuth";

function RouterAfterSignin() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { user } = useAuth();
  const [title, setTitle] = useState();

  const handleTitle = useCallback((pageName) => {
    setTitle({ ...pageName });
  }, []);

  if (!isMobile) {
    return <MobileOnlyAccess />;
  }

  return (
    <>
      <Menu title={title} />
      <Box className="mobile">
        <Routes>
          <Route
            path="/"
            element={
              <Main
                isMobile={isMobile}
                handleTitle={handleTitle}
                name={"메인"}
                user={user}
              />
            }
          />
          <Route
            path="/main"
            element={
              <Main
                isMobile={isMobile}
                handleTitle={handleTitle}
                name={"메인"}
                user={user}
              />
            }
          />
          <Route
            path="/mypage"
            element={
              <Mypage
                isMobile={isMobile}
                handleTitle={handleTitle}
                user={user}
              />
            }
          />
          <Route
            path="/mypage/mod"
            element={
              <ModMyInfo isMobile={isMobile} handleTitle={handleTitle} />
            }
          />
          <Route
            path="/mypage/modUser"
            element={
              <ModUserInfo isMobile={isMobile} handleTitle={handleTitle} />
            }
          />

          <Route
            path="/inventory"
            element={
              <InventoryInfo isMobile={isMobile} handleTitle={handleTitle} />
            }
          />
          <Route
            path="/stock"
            element={
              <StockInfo isMobile={isMobile} handleTitle={handleTitle} />
            }
          />
          <Route
            path="/trace-mobile"
            element={
              <EnhancedSerialManagerForMobile
                isMobile={isMobile}
                handleTitle={handleTitle}
              />
            }
          />
          <Route
            path="/serials"
            element={
              <ManageSerials isMobile={isMobile} handleTitle={handleTitle} />
            }
          />
          <Route
            path="/safe-stock"
            element={
              <SafeStock isMobile={isMobile} handleTitle={handleTitle} />
            }
          />

          <Route
            path="/*"
            element={<NotFound isMobile={isMobile} handleTitle={handleTitle} />}
          />
        </Routes>
      </Box>
    </>
  );
}

export default RouterAfterSignin;
