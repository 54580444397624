import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';

export const NotFound = ({ isMobile }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // 페이지 타이틀 업데이트
    document.title = '404 Not Found';
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: isMobile ? 2 : 4,
      }}
    >
      <ErrorOutlineIcon
        sx={{ fontSize: isMobile ? 60 : 80, color: 'error.main' }}
      />
      <Typography
        variant={isMobile ? 'h5' : 'h4'}
        sx={{ mt: 2, textAlign: 'center' }}
      >
        죄송합니다. 페이지를 찾을 수 없습니다.
      </Typography>
      <Button variant='contained' sx={{ mt: 3 }} onClick={() => navigate('/')}>
        홈으로 돌아가기
      </Button>
    </Box>
  );
};

export default NotFound;
